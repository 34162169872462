<!--  -->
<template>
  <div id="Pratice">
    <VueDragResize
      v-if="quanpingshow"
      v-on:resizing="resize"
      v-on:dragging="resize"
      :isActive="true"
      :w="800"
      :h="500"
    >
      <el-dialog
        class="question"
        title="按住可随意拖动"
        :visible.sync="quanpingshow"
        :close-on-click-modal="false"
        :modal="false"
        :modal-append-to-body="false"
        :fullscreen="false"
        :style="{ top: top + 'px', left: left + 'px' }"
        :before-close="handleClose"
      >
        <div v-html="gridData"></div>
      </el-dialog>
    </VueDragResize>
    <el-dialog
      class="tijiaoDialog"
      :visible.sync="centerDialogVisible"
      width="30%"
      center
    >
      <span>你还有试题没做完</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="centerDialogVisible = false">继 续</el-button>
        <el-button type="primary" @click="clicksubmit">结束练习</el-button>
      </span>
    </el-dialog>
    <el-dialog
      class="tijiaoDialog"
      :visible.sync="DialogVisible"
      width="30%"
      center
    >
      <span>恭喜你！试题已完成</span>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="clicksubmit">结束练习</el-button>
      </span>
    </el-dialog>
    <div class="Pratice_right">
      <el-dialog
        title="评论
        "
        :visible.sync="commentBool"
        width="300px"
        class="comment"
        :modal="false"
        :close-on-click-modal="false"
      >
        <div class="commentTop">
          <div @click="commentBool = false">关闭</div>
          <div>{{ discussObj.total }}条评论</div>
          <div @click="commentRedBool = true">写评论</div>
        </div>
        <div class="discuss">
          <ul class="discuss_list" v-if="discussObj.total > 0">
            <li v-for="(item, index) in discussObj.data" :key="index">
              <div>
                <img v-if="item.user.avatar" :src="item.user.avatar" alt="" />
                <img v-else src="@/assets/image/avatar.png" alt="" />
              </div>
              <div class="discuss_list_right">
                <div class="discuss_list_top">
                  <div>{{ item.user.name }}</div>
                  <div class="discuss_list_thumbs">
                    <img
                      src="@/assets/image/thumbs-up1.png"
                      alt=""
                      v-if="item.support.length == 0"
                      @click="clickSupport(item.id, '点赞')"
                    />
                    <img
                      src="@/assets/image/thumbs-up.png"
                      alt=""
                      v-else
                      @click="clickSupport(item.id, '取消点赞')"
                    /><span>{{ item.support_count }}</span>
                  </div>
                </div>
                <div class="discuss_list_time">{{ item.created_at }}</div>
                <div
                  class="discuss_list_ask"
                  :style="{ display: item.commenttextclass ? 'flex' : '' }"
                >
                  <span :class="item.commenttextclass ? 'commenttext' : ''">{{
                    item.commenttext
                  }}</span
                  ><button
                    v-if="item.commenttext.length > 60"
                    @click="clickshowask(index)"
                    :class="item.commenttextclass ? 'active' : ''"
                  >
                    -{{ item.msg }}
                  </button>
                  <!-- <p>{{ item.commenttext }}</p>
                  <button v-if="item.commenttext.length > 60">
                    -{{ item.msg }}
                  </button> -->
                </div>
                <div
                  class="discuss_list_answer"
                  v-for="(item1, index1) in item.son"
                  :key="index1"
                >
                  <span class="discuss_list_answer_name" v-if="item1.name"
                    >{{ item1.name }}：</span
                  ><span class="discuss_list_answer_name" v-else
                    >{{ item1.user.name }}：</span
                  >
                  <p :style="{ display: item1.commenttextclass ? 'flex' : '' }">
                    <span
                      :class="item1.commenttextclass ? 'commenttext' : ''"
                      >{{ item1.commenttext }}</span
                    ><button
                      v-if="item1.commenttext.length > 14"
                      @click="clickshowcommenttext(index, index1)"
                    >
                      -{{ item1.msg }}
                    </button>
                  </p>
                  <!-- <p
                    :class="
                      item1.commenttextclass ? 'discuss_list_answer_stem' : ''
                    "
                  >
                    {{ item1.commenttext }}
                  </p>
                  <button
                    v-if="item1.commenttext.length > 14"
                    @click="clickshowcommenttext(index, index1)"
                  >
                    {{ item1.msg }}
                  </button> -->
                  <!-- <div>
                    <p> {{ item1.commenttext }}</p>
                  </div> -->
                </div>
              </div>
            </li>
          </ul>
          <div v-else>暂无评论</div>
        </div>

        <el-pagination
          small
          @current-change="handleCurrentChange"
          :page-size="pageSize.limit"
          :current-page.sync="pageSize.page"
          layout="total, prev, pager, next"
          :total="discussObj.total"
        >
        </el-pagination>

        <!-- :hide-on-single-page="true" -->
        <!-- <span slot="footer" class="dialog-footer">
          <el-button @click="commentBool = false">取 消</el-button>
          <el-button type="primary" @click="commentBool = false"
            >确 定</el-button
          >
        </span> -->
      </el-dialog>
      <el-dialog
        title="发表评论"
        :visible.sync="commentRedBool"
        width="500px"
        class="commentRedBool"
        @close="(commentRedBool = false), (discussCreate = '')"
      >
        <textarea
          v-model="discussCreate"
          rows=""
          cols=""
          placeholder="写下你的评论..."
        ></textarea>
        <span slot="footer" class="dialog-footer">
          <el-button @click="(commentRedBool = false), (discussCreate = '')"
            >取 消</el-button
          >
          <el-button type="primary" @click="clickDiscussCreate"
            >确 定</el-button
          >
        </span>
      </el-dialog>
      <div class="Pratice_text-name" v-show="difficultys">
        <p>{{ name }}</p>
        <div>
          <!-- <p>分数：{{ gradeall }}</p> -->
          <div>
            <p>难度</p>

            <el-rate v-model="difficultys" :colors="colors" disabled :size="40">
            </el-rate>
          </div>
        </div>
      </div>
      <div class="Pratice_right_top">
        <div style="color: #000">
          <img src="../../assets/image/45.png" />答题进度
        </div>
        <div>
          <div class="speed">
            <div
              :style="{ width: (number / allListData.length) * 100 + '%' }"
            ></div>
          </div>
          <div class="num">{{ number }}/{{ allListData.length }}</div>
        </div>
        <el-button type="danger" @click="jiaojuan">结束练习</el-button>
      </div>
      <div
        class="Pratice_right_main"
        :style="{ height: difficultys ? '545px' : '628px' }"
      >
        <!-- <img src="" alt=""> -->
        <div class="Pratice_right_main_title">记录板</div>
        <!-- <ul>
          <li class="text_done">
            <i>
              <img src="../../assets/image/Icon-success.png" alt="" />
            </i>
            <div class="text_done_right">
              <p>已完成</p>
              <p>
                <span>{{ number }}</span
                >道题
              </p>
            </div>
          </li>
          <li class="text_tag">
            <i>
              <img src="../../assets/image/Icon-info.png" alt="" />
            </i>
            <div class="text_done_right">
              <p>做标记</p>
              <p>
                <span>{{ biaojiNum }}</span
                >道题
              </p>
            </div>
          </li>
          <li class="text_undone">
            <i>
              <img src="../../assets/image/Icon-warning.png" alt="" />
            </i>
            <div class="text_done_right">
              <p>未完成</p>
              <p>
                <span>{{ allListData.length - number }}</span
                >道题
              </p>
            </div>
          </li>
        </ul> -->
        <ul class="index__answerHint clearfix">
          <li><i class="index__yizuo"></i>已做</li>
          <li><i class="index__weizuo"></i>未做</li>
          <li v-if="false">
            <i class="index__biaoji"></i>
            <i class="index__biaojixi"></i>标记
          </li>
        </ul>
        <div class="index__BigtiNumberBox">
          <div class="stem">【题目】</div>
          <ul>
            <li
              @click="clickCard(index)"
              v-for="(item, index) in allListData"
              :key="index"
              :class="[
                { active: item.makeQuestion },
                { cur: item.biaoji == 1 },
                { act: item.topicBool },
              ]"
            >
              {{ index + 1 }}
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="Pratice_left">
      <!-- <div class="Pratice_left_top">
        <div>{{ classname }}</div>
        <div>
          <div>章节测试卷</div>
          <i class="el-icon-arrow-right"></i>
          <div>{{ name }}</div>
        </div>
      </div> -->
      <div v-if="allListData.length > 0" class="Pratice_left_main">
        <div class="allList" v-for="(item, index) in allListData" :key="index">
          <div v-if="item.topicBool">
            <!-- 单选 -->
            <div
              class="singleChoice"
              v-if="item.question.type == 1 ? true : false"
            >
              <!-- <div class="allListTitle">
                  <div class="allListLeftborder"></div>
                  <div>
                    PRUEBA <span>{{ index + 1 }}</span>
                  </div>
                  <div>
                    <span v-if="item.question.defined == '' ? false : true"
                      >({{ item.question.defined }})</span
                    ><span v-else
                      >({{ item.question.type == 1 ? "单选题" : "" }})</span
                    >
                  </div>
                  <div>({{ item.question.grade }}分)</div>
                </div>-->
              <!-- 文字 -->
              <div
                class="singleWords"
                v-if="item.question.stem_type == 1 ? true : false"
              >
                <div class="allListStemTitle">
                  <div class="allListTitle">
                    <div class="allListLeftborder"></div>
                    <div>
                      <span>{{ index + 1 }}</span>
                    </div>
                    <div>
                      <span v-if="item.question.defined == '' ? false : true"
                        >({{ item.question.defined }})</span
                      ><span v-else>{{
                        item.question.type == 1 ? "单选题" : ""
                      }}</span>
                    </div>
                    <!-- <div>({{ item.question.grade }}分)</div> -->
                  </div>
                  <!-- <div class="left">
                      <span></span>
                      <div class="index">{{ index + 1 }}</div>
                      <div class="right-line"></div>
                    </div> -->

                  <div class="connction">
                    <div
                      v-if="item.question.collection == 1"
                      @click="clickActive(index)"
                    >
                      <van-icon size="1.5em" color="#ffb41c" name="star" />
                      <span>收藏</span>
                    </div>
                    <div v-else @click="clickActive(index)">
                      <van-icon color="#ccc" name="star" size="1.5em" />
                      <span>收藏</span>
                    </div>

                    <!-- <img
                      @click="sybelBtn(index)"
                      :src="item.biaoji == 0 ? item.sybelImg : item.sybelPath"
                      alt=""
                    />
                    <p>标记</p> -->
                  </div>
                </div>
                <div class="stemBox common-stem">
                  <div class="singleStem" v-html="item.question.stem">
                    <div class="index">{{ index + 1 }}</div>
                  </div>
                  <div
                    v-for="(item1, index1) in item.question.single_choices"
                    :key="index1"
                    class="singleSelectList"
                    @click="!item.isShowJx && clickSingle(index, index1)"
                  >
                    <div :class="item1.isSelect ? 'isSelect' : ''">
                      <span>{{ listSum[index1] }}</span>
                    </div>
                    <p v-html="item1.answer"></p>
                  </div>
                  <div class="btn">
                    <div></div>
                    <el-button
                      type="primary"
                      :disabled="!item.makeQuestion"
                      :class="item.isShowJx ? 'activeJx' : ''"
                      @click="clickShowJx(index)"
                    >
                      <i class="el-icon-s-claim"></i>验证答案</el-button
                    >
                  </div>
                  <div class="analysis" v-show="item.isShowJx">
                    <div class="analysisDa">
                      <div class="analysisDaQuery">
                        <div class="nide" style="display: inline-block">
                          <div>你的答案:</div>
                          <div
                            class="youanswer"
                            v-for="(item1, index1) in item.question
                              .single_choices"
                            :key="index1"
                            v-show="item1.isSelect"
                          >
                            {{ listSum[index1] }}
                            <i
                              class="iconfont icon-zhengque1"
                              style="color: #25c17c; font-size: 20px"
                              v-show="
                                item1.yes == 1 && item1.isSelect ? true : false
                              "
                            ></i
                            ><i
                              class="iconfont icon-cuowu"
                              style="color: #f4433c; font-size: 20px"
                              v-show="
                                item1.yes == 0 && item1.isSelect ? true : false
                              "
                            ></i>
                          </div>
                        </div>
                        <div class="daan" style="display: inline-block">
                          <div>参考答案:</div>
                          <div
                            v-for="(item1, index1) in item.question
                              .single_choices"
                            :key="index1"
                            v-show="item1.yes == 1 ? true : false"
                          >
                            {{ listSum[index1] }}
                          </div>
                        </div>
                      </div>
                      <div class="jiexi">
                        <div>解析:</div>
                        <div
                          class="praticeAnalysis"
                          v-html="item.question.analysis"
                          v-if="item.question.analysis == '' ? false : true"
                        ></div>
                        <span class="praticeAnalysis" v-else>暂无</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- 音频 -->
              <div
                class="singleAudio"
                v-if="item.question.stem_type == 2 ? true : false"
              >
                <div class="allListStemTitle">
                  <!-- <div class="left">
                      <span></span>
                      <div class="index">{{ index + 1 }}</div>
                      <div class="right-line"></div>
                    </div> -->
                  <div class="allListStemTitle">
                    <div class="allListTitle">
                      <div class="allListLeftborder"></div>
                      <div>
                        <span>{{ index + 1 }}</span>
                      </div>
                      <div>
                        <span v-if="item.question.defined == '' ? false : true"
                          >({{ item.question.defined }})</span
                        ><span v-else
                          >({{ item.question.type == 1 ? "单选题" : "" }})</span
                        >
                      </div>
                      <!-- <div>({{ item.question.grade }}分)</div> -->
                    </div>
                    <!-- <div class="left">
                      <span></span>
                      <div class="index">{{ index + 1 }}</div>
                      <div class="right-line"></div>
                    </div> -->
                  </div>

                  <div class="connction">
                    <div
                      v-if="item.question.collection == 1"
                      @click="clickActive(index)"
                    >
                      <van-icon size="1.5em" color="#ffb41c" name="star" />
                      <span>收藏</span>
                    </div>
                    <div v-else @click="clickActive(index)">
                      <van-icon color="#ccc" name="star" size="1.5em" />
                      <span>收藏</span>
                    </div>

                    <!-- <img
                      @click="sybelBtn(index)"
                      :src="item.biaoji == 0 ? item.sybelImg : item.sybelPath"
                      alt=""
                    />
                    <p>标记</p> -->
                  </div>
                </div>
                <div class="stemBox common-stem">
                  <div class="audioBox" v-if="item.question.file">
                    <div class="btnback" v-if="item.question.file">
                      <div class="back" @click="clickSnd(-15)">
                        <img src="../../assets/image/kuaijin1.png" alt="" />
                      </div>
                      <div>{{ item.question.file.filename }}</div>
                      <div class="forward" @click="clickSnd(15)">
                        <img src="../../assets/image/kuaijin2.png" alt="" />
                      </div>
                    </div>
                    <audio
                      v-if="item.question.file"
                      controls
                      controlslist="nodownload"
                    >
                      <source
                        :src="item.question.file.playpath"
                        type="audio/mp3"
                      />
                      您的浏览器不支持 HTML5 audio 标签。
                    </audio>
                  </div>

                  <div class="singleStem" v-html="item.question.stem"></div>

                  <div
                    v-for="(item1, index1) in item.question.single_choices"
                    :key="index1"
                    class="singleSelectList"
                    @click="!item.isShowJx && clickSingle(index, index1)"
                  >
                    <div :class="item1.isSelect ? 'isSelect' : ''">
                      {{ listSum[index1] }}
                    </div>
                    <p v-html="item1.answer"></p>
                  </div>
                  <div class="btn">
                    <div></div>
                    <el-button
                      type="primary"
                      :disabled="!item.makeQuestion"
                      :class="item.isShowJx ? 'activeJx' : ''"
                      @click="clickShowJx(index)"
                      ><i class="el-icon-s-claim"></i>验证答案</el-button
                    >
                  </div>
                  <div class="analysis" v-show="item.isShowJx">
                    <div class="analysisDa">
                      <div class="analysisDaQuery">
                        <div class="nide" style="display: inline-block">
                          <div>你的答案</div>
                          <div
                            class="youanswer"
                            v-for="(item1, index1) in item.question
                              .single_choices"
                            :key="index1"
                            v-show="item1.isSelect"
                          >
                            {{ listSum[index1] }}
                            <i
                              class="iconfont icon-zhengque1"
                              style="color: #25c17c; font-size: 20px"
                              v-if="
                                item1.yes == 1 && item1.isSelect ? true : false
                              "
                            ></i
                            ><i
                              class="iconfont icon-cuowu"
                              style="color: #f4433c; font-size: 20px"
                              v-if="
                                item1.yes == 0 && item1.isSelect ? true : false
                              "
                            ></i>
                          </div>
                        </div>
                        <div
                          class="daan"
                          style="display: inline-block; margin-left: 20px"
                        >
                          <h4>参考答案:</h4>
                          <div
                            v-for="(item1, index1) in item.question
                              .single_choices"
                            :key="index1"
                            v-show="item1.yes == 1 ? true : false"
                          >
                            {{ listSum[index1] }}
                          </div>
                        </div>
                      </div>
                      <div class="jiexi">
                        <div>解析:</div>
                        <div
                          class="praticeAnalysis"
                          v-html="item.question.analysis"
                          v-if="item.question.analysis == '' ? false : true"
                        ></div>
                        <span class="praticeAnalysis" v-else>暂无</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- 视频 -->
              <div
                class="singleVideo"
                v-if="item.question.stem_type == 3 ? true : false"
              >
                <div class="allListStemTitle">
                  <!-- <div class="left">
                      <span></span>
                      <div class="index">{{ index + 1 }}</div>
                      <div class="right-line"></div>
                    </div> -->

                  <div class="allListTitle">
                    <div class="allListLeftborder"></div>
                    <div>
                      <span>{{ index + 1 }}</span>
                    </div>
                    <div>
                      <span v-if="item.question.defined == '' ? false : true"
                        >({{ item.question.defined }})</span
                      ><span v-else>{{
                        item.question.type == 1 ? "单选题" : ""
                      }}</span>
                    </div>
                    <!-- <div>({{ item.question.grade }}分)</div> -->
                  </div>
                  <div class="connction">
                    <div
                      v-if="item.question.collection == 1"
                      @click="clickActive(index)"
                    >
                      <van-icon size="1.5em" color="#ffb41c" name="star" />
                      <span>收藏</span>
                    </div>
                    <div v-else @click="clickActive(index)">
                      <van-icon color="#ccc" name="star" size="1.5em" />
                      <span>收藏</span>
                    </div>

                    <!-- <img
                      @click="sybelBtn(index)"
                      :src="item.biaoji == 0 ? item.sybelImg : item.sybelPath"
                      alt=""
                    />
                    <p>标记</p> -->
                  </div>
                </div>
                <div class="stemBox common-stem">
                  <video
                    :src="item.question.file.playpath"
                    controls="controls"
                  ></video>
                  <div class="singleStem" v-html="item.question.stem"></div>

                  <div
                    v-for="(item1, index1) in item.question.single_choices"
                    :key="index1"
                    class="singleSelectList"
                    @click="!item.isShowJx && clickSingle(index, index1)"
                  >
                    <div :class="item1.isSelect ? 'isSelect' : ''">
                      {{ listSum[index1] }}
                    </div>
                    <p v-html="item1.answer"></p>
                  </div>
                  <div class="btn">
                    <div></div>
                    <el-button
                      type="primary"
                      :disabled="!item.makeQuestion"
                      :class="item.isShowJx ? 'activeJx' : ''"
                      @click="clickShowJx(index)"
                      ><i class="el-icon-s-claim"></i>验证答案</el-button
                    >
                  </div>
                  <div class="analysis" v-show="item.isShowJx">
                    <div class="analysisDa">
                      <div class="analysisDaQuery">
                        <div class="nide" style="display: inline-block">
                          <div>你的答案</div>
                          <div
                            class="youanswer"
                            v-for="(item1, index1) in item.question
                              .single_choices"
                            :key="index1"
                            v-show="item1.isSelect"
                          >
                            {{ listSum[index1] }}
                            <i
                              class="iconfont icon-zhengque1"
                              style="color: #25c17c; font-size: 20px"
                              v-if="
                                item1.yes == 1 && item1.isSelect ? true : false
                              "
                            ></i
                            ><i
                              class="iconfont icon-cuowu"
                              style="color: #f4433c; font-size: 20px"
                              v-if="
                                item.yes == 0 && item1.isSelect ? true : false
                              "
                            ></i>
                          </div>
                        </div>
                        <div
                          class="daan"
                          style="display: inline-block; margin-left: 20px"
                        >
                          <h4>参考答案:</h4>
                          <div
                            v-for="(item1, index1) in item.question
                              .single_choices"
                            :key="index1"
                            v-show="item1.yes == 1 ? true : false"
                          >
                            {{ listSum[index1] }}
                          </div>
                        </div>
                      </div>
                      <div class="jiexi">
                        <div>解析:</div>
                        <div
                          class="praticeAnalysis"
                          v-html="item.question.analysis"
                          v-if="item.question.analysis == '' ? false : true"
                        ></div>
                        <span
                          class="praticeAnalysis"
                          v-if="item.question.analysis == '' ? true : false"
                          >暂无</span
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- 图片 -->
              <div
                class="singleVideo"
                v-if="item.question.stem_type == 4 ? true : false"
              >
                <!-- <div class="allListStemTitle">
                    <div class="index">{{ index + 1 }}</div>
                    <span></span>
                  </div> -->
                <div class="allListStemTitle">
                  <!-- <div class="left">
                      <span></span>
                      <div class="index">{{ index + 1 }}</div>
                      <div class="right-line"></div>
                    </div> -->
                  <div class="allListTitle">
                    <div class="allListLeftborder"></div>
                    <div>
                      <span>{{ index + 1 }}</span>
                    </div>
                    <div>
                      <span v-if="item.question.defined == '' ? false : true"
                        >({{ item.question.defined }})</span
                      ><span v-else>{{
                        item.question.type == 1 ? "单选题" : ""
                      }}</span>
                    </div>
                    <!-- <div>({{ item.question.grade }}分)</div> -->
                  </div>
                  <div class="connction">
                    <div
                      v-if="item.question.collection == 1"
                      @click="clickActive(index)"
                    >
                      <van-icon size="1.5em" color="#ffb41c" name="star" />
                      <span>收藏</span>
                    </div>
                    <div v-else @click="clickActive(index)">
                      <van-icon color="#ccc" name="star" size="1.5em" />
                      <span>收藏</span>
                    </div>

                    <!-- <img
                      @click="sybelBtn(index)"
                      :src="item.biaoji == 0 ? item.sybelImg : item.sybelPath"
                      alt=""
                    />
                    <p>标记</p> -->
                  </div>
                </div>
                <div class="stemBox common-stem">
                  <div class="timu_box">
                    <img
                      @click="clickShowImagePreview(index)"
                      :src="item.question.file.playpath"
                      v-if="item.question.file"
                      alt=""
                    />
                    <div class="singleStem" v-html="item.question.stem"></div>

                    <div
                      v-for="(item1, index1) in item.question.single_choices"
                      :key="index1"
                      class="singleSelectList"
                      @click="!item.isShowJx && clickSingle(index, index1)"
                    >
                      <div :class="item1.isSelect ? 'isSelect' : ''">
                        {{ listSum[index1] }}
                      </div>
                      <p v-html="item1.answer"></p>
                    </div>
                  </div>
                  <div class="btn">
                    <div></div>
                    <el-button
                      type="primary"
                      :disabled="!item.makeQuestion"
                      :class="item.isShowJx ? 'activeJx' : ''"
                      @click="clickShowJx(index)"
                      ><i class="el-icon-s-claim"></i>验证答案</el-button
                    >
                  </div>
                  <div class="analysis" v-show="item.isShowJx">
                    <div class="analysisDa">
                      <div class="analysisDaQuery">
                        <div class="nide" style="display: inline-block">
                          <div>你的答案</div>
                          <div
                            class="youanswer"
                            v-for="(item1, index1) in item.question
                              .single_choices"
                            :key="index1"
                            v-show="item1.isSelect"
                          >
                            {{ listSum[index1] }}
                            <i
                              class="iconfont icon-zhengque1"
                              style="color: #25c17c; font-size: 20px"
                              v-if="
                                item.yes == 1 && item1.isSelect ? true : false
                              "
                            ></i
                            ><i
                              class="iconfont icon-cuowu"
                              style="color: #f4433c; font-size: 20px"
                              v-if="
                                item.yes == 0 && item1.isSelect ? true : false
                              "
                            ></i>
                          </div>
                        </div>
                        <div
                          class="daan"
                          style="display: inline-block; margin-left: 20px"
                        >
                          <h4>参考答案:</h4>
                          <div
                            v-for="(item1, index1) in item.question
                              .single_choices"
                            :key="index1"
                            v-show="item1.yes == 1 ? true : false"
                          >
                            {{ listSum[index1] }}
                          </div>
                        </div>
                      </div>
                      <div class="jiexi">
                        <div>解析:</div>
                        <div
                          class="praticeAnalysis"
                          v-html="item.question.analysis"
                          v-if="item.question.analysis == '' ? false : true"
                        ></div>
                        <span
                          class="praticeAnalysis"
                          v-if="item.question.analysis == '' ? true : false"
                          >暂无</span
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- 多选题 -->
            <div
              class="multipleChoice"
              v-if="item.question.type == 2 ? true : false"
            >
              <div
                class="multipleWords"
                v-if="item.question.stem_type == 1 ? true : false"
              >
                <div class="allListStemTitle">
                  <!-- <div class="left">
                      <span></span>
                      <div class="index">{{ index + 1 }}</div>
                      <div class="right-line"></div>
                    </div> -->
                  <div class="allListTitle">
                    <div class="allListLeftborder"></div>
                    <div>
                      <span>{{ index + 1 }}</span>
                    </div>
                    <div>
                      <span v-if="item.question.defined == '' ? false : true"
                        >({{ item.question.defined }})</span
                      ><span v-else>{{
                        item.question.type == 2 ? "多选题" : ""
                      }}</span>
                    </div>
                    <!-- <div>({{ item.question.grade }}分)</div> -->
                  </div>
                  <div class="connction">
                    <div
                      v-if="item.question.collection == 1"
                      @click="clickActive(index)"
                    >
                      <van-icon size="1.5em" color="#ffb41c" name="star" />
                      <span>收藏</span>
                    </div>
                    <div v-else @click="clickActive(index)">
                      <van-icon color="#ccc" name="star" size="1.5em" />
                      <span>收藏</span>
                    </div>

                    <!-- <img
                      @click="sybelBtn(index)"
                      :src="item.biaoji == 0 ? item.sybelImg : item.sybelPath"
                      alt=""
                    />
                    <p>标记</p> -->
                  </div>
                </div>
                <div class="stemBox common-stem">
                  <div class="multipleStem" v-html="item.question.stem"></div>
                  <div
                    v-for="(item1, index1) in item.question.multiple_choices"
                    :key="index1"
                    class="multipleSelectList"
                    @click="!item.isShowJx && clickMany(index, index1)"
                  >
                    <div :class="item1.isSelect ? 'isSelect' : ''">
                      {{ listSum[index1] }}
                    </div>
                    <p v-html="item1.answer"></p>
                  </div>
                  <div class="btn">
                    <div></div>
                    <el-button
                      type="primary"
                      :disabled="!item.makeQuestion"
                      :class="item.isShowJx ? 'activeJx' : ''"
                      @click="clickShowJx(index)"
                      ><i class="el-icon-s-claim"></i>验证答案</el-button
                    >
                  </div>
                  <div class="analysis" v-show="item.isShowJx">
                    <div class="analysisDa">
                      <div class="analysisDaQuery">
                        <div class="nide" style="display: inline-block">
                          <div>你的答案</div>
                          <span
                            class="youanswer"
                            v-for="(item1, index1) in item.question
                              .multiple_choices"
                            :key="index1"
                            v-show="item1.isSelect"
                          >
                            <i
                              class="iconfont icon-zhengque1"
                              style="color: #25c17c; font-size: 20px"
                              v-if="
                                item1.yes == 1 && item1.isSelect ? true : false
                              "
                            ></i
                            ><i
                              class="iconfont icon-cuowu"
                              style="color: #f4433c; font-size: 20px"
                              v-if="
                                item1.yes == 0 && item1.isSelect ? true : false
                              "
                            ></i>
                            {{ listSum[index1] }}
                          </span>
                        </div>
                        <div class="daan" style="display: inline-block">
                          <div>参考答案:</div>
                          <span
                            v-for="(item1, index1) in item.question
                              .multiple_choices"
                            :key="index1"
                            v-show="item1.yes == 1 ? true : false"
                          >
                            {{ listSum[index1] }}
                          </span>
                        </div>
                      </div>
                      <div class="jiexi">
                        <div>解析：</div>
                        <div
                          class="praticeAnalysis"
                          v-html="item.question.analysis"
                          v-if="item.question.analysis == '' ? false : true"
                        ></div>
                        <span
                          class="praticeAnalysis"
                          v-if="item.question.analysis == '' ? true : false"
                          >暂无</span
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- 音频 -->
              <div
                class="multipleAudio"
                v-if="item.question.stem_type == 2 ? true : false"
              >
                <div class="allListStemTitle">
                  <!-- <div class="left">
                      <span></span>
                      <div class="index">{{ index + 1 }}</div>
                      <div class="right-line"></div>
                    </div> -->
                  <div class="allListTitle">
                    <div class="allListLeftborder"></div>
                    <div>
                      <span>{{ index + 1 }}</span>
                    </div>
                    <div>
                      <span v-if="item.question.defined == '' ? false : true"
                        >({{ item.question.defined }})</span
                      ><span v-else>{{
                        item.question.type == 2 ? "多选题" : ""
                      }}</span>
                    </div>
                    <!-- <div>({{ item.question.grade }}分)</div> -->
                  </div>

                  <div class="connction">
                    <div
                      v-if="item.question.collection == 1"
                      @click="clickActive(index)"
                    >
                      <van-icon size="1.5em" color="#ffb41c" name="star" />
                      <span>收藏</span>
                    </div>
                    <div v-else @click="clickActive(index)">
                      <van-icon color="#ccc" name="star" size="1.5em" />
                      <span>收藏</span>
                    </div>

                    <!-- <img
                      @click="sybelBtn(index)"
                      :src="item.biaoji == 0 ? item.sybelImg : item.sybelPath"
                      alt=""
                    />
                    <p>标记</p> -->
                  </div>
                </div>
                <div class="stemBox common-stem">
                  <div class="audioBox" v-if="item.question.file">
                    <div class="btnback" v-if="item.question.file">
                      <div class="back" @click="clickSnd(-15)">
                        <img src="../../assets/image/kuaijin1.png" alt="" />
                      </div>
                      <div>{{ item.question.file.filename }}</div>
                      <div class="forward" @click="clickSnd(15)">
                        <img src="../../assets/image/kuaijin2.png" alt="" />
                      </div>
                    </div>
                    <audio
                      v-if="item.question.file"
                      controls
                      controlslist="nodownload"
                    >
                      <source
                        :src="item.question.file.playpath"
                        type="audio/mp3"
                      />
                      您的浏览器不支持 HTML5 audio 标签。
                    </audio>
                  </div>

                  <div class="multipleStem" v-html="item.question.stem"></div>

                  <div
                    v-for="(item1, index1) in item.question.multiple_choices"
                    :key="index1"
                    class="multipleSelectList"
                    @click="!item.isShowJx && clickMany(index, index1)"
                  >
                    <div :class="item1.isSelect ? 'isSelect' : ''">
                      {{ listSum[index1] }}
                    </div>
                    <p v-html="item1.answer"></p>
                  </div>
                  <div class="btn">
                    <div></div>
                    <el-button
                      type="primary"
                      :disabled="!item.makeQuestion"
                      :class="item.isShowJx ? 'activeJx' : ''"
                      @click="clickShowJx(index)"
                      ><i class="el-icon-s-claim"></i>验证答案</el-button
                    >
                  </div>
                  <div class="analysis" v-show="item.isShowJx">
                    <div class="analysisDa">
                      <div>
                        <div class="nide" style="display: inline-block">
                          <div>你的答案</div>
                          <span
                            class="youanswer"
                            v-for="(item1, index1) in item.question
                              .multiple_choices"
                            :key="index1"
                            v-show="item1.isSelect"
                          >
                            <i
                              class="iconfont icon-zhengque1"
                              style="color: #25c17c; font-size: 20px"
                              v-if="
                                item1.yes == 1 && item1.isSelect ? true : false
                              "
                            ></i
                            ><i
                              class="iconfont icon-cuowu"
                              style="color: #f4433c; font-size: 20px"
                              v-if="
                                item1.yes == 0 && item1.isSelect ? true : false
                              "
                            ></i>
                            {{ listSum[index1] }}
                          </span>
                        </div>
                        <div
                          class="daan"
                          style="display: inline-block; margin-left: 20px"
                        >
                          <h4>参考答案:</h4>
                          <span
                            v-for="(item1, index1) in item.question
                              .multiple_choices"
                            :key="index1"
                            v-show="item1.yes == 1 ? true : false"
                          >
                            {{ listSum[index1] }}
                          </span>
                        </div>
                      </div>
                      <div class="jiexi">
                        <div>解析：</div>
                        <div
                          class="praticeAnalysis"
                          v-html="item.question.analysis"
                          v-if="item.question.analysis == '' ? false : true"
                        ></div>
                        <span
                          class="praticeAnalysis"
                          v-if="item.question.analysis == '' ? true : false"
                          >暂无</span
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- 视频 -->
              <div
                class="multipleVideo"
                v-if="item.question.stem_type == 3 ? true : false"
              >
                <div class="allListStemTitle">
                  <div class="allListTitle">
                    <div class="allListLeftborder"></div>
                    <div>
                      <span>{{ index + 1 }}</span>
                    </div>
                    <div>
                      <span v-if="item.question.defined == '' ? false : true"
                        >({{ item.question.defined }})</span
                      ><span v-else
                        >({{ item.question.type == 2 ? "多选题" : "" }})</span
                      >
                    </div>
                    <!-- <div>({{ item.question.grade }}分)</div> -->
                  </div>

                  <div class="connction">
                    <div
                      v-if="item.question.collection == 1"
                      @click="clickActive(index)"
                    >
                      <van-icon size="1.5em" color="#ffb41c" name="star" />
                      <span>收藏</span>
                    </div>
                    <div v-else @click="clickActive(index)">
                      <van-icon color="#ccc" name="star" size="1.5em" />
                      <span>收藏</span>
                    </div>

                    <!-- <img
                      @click="sybelBtn(index)"
                      :src="item.biaoji == 0 ? item.sybelImg : item.sybelPath"
                      alt=""
                    />
                    <p>标记</p> -->
                  </div>
                </div>
                <div class="stemBox common-stem">
                  <video
                    :src="item.question.file.playpath"
                    controls="controls"
                  ></video>
                  <div class="multipleStem" v-html="item.question.stem"></div>

                  <div
                    v-for="(item1, index1) in item.question.multiple_choices"
                    :key="index1"
                    class="multipleSelectList"
                    @click="!item.isShowJx && clickMany(index, index1)"
                  >
                    <div :class="item1.isSelect ? 'isSelect' : ''">
                      {{ listSum[index1] }}
                    </div>
                    <p v-html="item1.answer"></p>
                  </div>
                  <div class="btn">
                    <div></div>
                    <el-button
                      type="primary"
                      :disabled="!item.makeQuestion"
                      :class="item.isShowJx ? 'activeJx' : ''"
                      @click="clickShowJx(index)"
                      ><i class="el-icon-s-claim"></i>验证答案</el-button
                    >
                  </div>
                  <div class="analysis" v-show="item.isShowJx">
                    <div class="analysisDa">
                      <div>
                        <div class="nide" style="display: inline-block">
                          <div>你的答案</div>
                          <span
                            class="youanswer"
                            v-for="(item1, index1) in item.question
                              .multiple_choices"
                            :key="index1"
                            v-show="item1.isSelect"
                          >
                            <i
                              class="iconfont icon-zhengque1"
                              style="color: #25c17c; font-size: 20px"
                              v-if="
                                item1.yes == 1 && item1.isSelect ? true : false
                              "
                            ></i
                            ><i
                              class="iconfont icon-cuowu"
                              style="color: #f4433c; font-size: 20px"
                              v-if="
                                item1.yes == 0 && item1.isSelect ? true : false
                              "
                            ></i>
                            {{ listSum[index1] }}
                          </span>
                        </div>
                        <div
                          class="daan"
                          style="display: inline-block; margin-left: 20px"
                        >
                          <h4>参考答案:</h4>
                          <span
                            v-for="(item1, index1) in item.question
                              .multiple_choices"
                            :key="index1"
                            v-show="item1.yes == 1 ? true : false"
                          >
                            {{ listSum[index1] }}
                          </span>
                        </div>
                      </div>
                      <div class="jiexi">
                        <div>解析：</div>
                        <div
                          class="praticeAnalysis"
                          v-html="item.question.analysis"
                          v-if="item.question.analysis == '' ? false : true"
                        ></div>
                        <span
                          class="praticeAnalysis"
                          v-if="item.question.analysis == '' ? true : false"
                          >暂无</span
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- 图片 -->
              <div
                class="multipleVideo"
                v-if="item.question.stem_type == 4 ? true : false"
              >
                <div class="allListStemTitle">
                  <div class="allListTitle">
                    <div class="allListLeftborder"></div>
                    <div>
                      <span>{{ index + 1 }}</span>
                    </div>
                    <div>
                      <span v-if="item.question.defined == '' ? false : true"
                        >({{ item.question.defined }})</span
                      ><span v-else>{{
                        item.question.type == 2 ? "多选题" : ""
                      }}</span>
                    </div>
                    <!-- <div>({{ item.question.grade }}分)</div> -->
                  </div>

                  <div class="connction">
                    <div
                      v-if="item.question.collection == 1"
                      @click="clickActive(index)"
                    >
                      <van-icon size="1.5em" color="#ffb41c" name="star" />
                      <span>收藏</span>
                    </div>
                    <div v-else @click="clickActive(index)">
                      <van-icon color="#ccc" name="star" size="1.5em" />
                      <span>收藏</span>
                    </div>

                    <!-- <img
                      @click="sybelBtn(index)"
                      :src="item.biaoji == 0 ? item.sybelImg : item.sybelPath"
                      alt=""
                    />
                    <p>标记</p> -->
                  </div>
                </div>
                <div class="stemBox common-stem">
                  <img
                    @click="clickShowImagePreview(index)"
                    :src="item.question.file.playpath"
                    v-if="item.question.file"
                    alt=""
                  />
                  <div class="multipleStem" v-html="item.question.stem"></div>

                  <div
                    v-for="(item1, index1) in item.question.multiple_choices"
                    :key="index1"
                    class="multipleSelectList"
                    @click="!item.isShowJx && clickMany(index, index1)"
                  >
                    <div :class="item1.isSelect ? 'isSelect' : ''">
                      {{ listSum[index1] }}
                    </div>
                    <p v-html="item1.answer"></p>
                  </div>
                  <div class="btn">
                    <div></div>
                    <el-button
                      type="primary"
                      :disabled="!item.makeQuestion"
                      :class="item.isShowJx ? 'activeJx' : ''"
                      @click="clickShowJx(index)"
                      ><i class="el-icon-s-claim"></i>验证答案</el-button
                    >
                  </div>
                  <div class="analysis" v-show="item.isShowJx">
                    <div class="analysisDa">
                      <div>
                        <div class="nide" style="display: inline-block">
                          <div>你的答案</div>
                          <span
                            class="youanswer"
                            v-for="(item1, index1) in item.question
                              .multiple_choices"
                            :key="index1"
                            v-show="item1.isSelect"
                          >
                            <i
                              class="iconfont icon-zhengque1"
                              style="color: #25c17c; font-size: 20px"
                              v-if="
                                item1.yes == 1 && item1.isSelect ? true : false
                              "
                            ></i
                            ><i
                              class="iconfont icon-cuowu"
                              style="color: #f4433c; font-size: 20px"
                              v-if="
                                item1.yes == 0 && item1.isSelect ? true : false
                              "
                            ></i>
                            {{ listSum[index1] }}
                          </span>
                        </div>
                        <div
                          class="daan"
                          style="display: inline-block; margin-left: 20px"
                        >
                          <h4>参考答案:</h4>
                          <span
                            v-for="(item1, index1) in item.question
                              .multiple_choices"
                            :key="index1"
                            v-show="item1.yes == 1 ? true : false"
                          >
                            {{ listSum[index1] }}
                          </span>
                        </div>
                      </div>
                      <div class="jiexi">
                        <div>解析：</div>
                        <div
                          class="praticeAnalysis"
                          v-if="item.question.analysis == '' ? false : true"
                          v-html="item.question.analysis"
                        ></div>
                        <span
                          class="praticeAnalysis"
                          v-if="item.question.analysis == '' ? true : false"
                          >暂无</span
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- 判断题 -->
            <div
              class="judgesChoice"
              v-if="item.question.type == 3 ? true : false"
            >
              <!-- <div class="allListTitle">
                  <div class="allListLeftborder"></div>
                  <div>
                    PRUEBA <span>{{ index + 1 }}</span>
                  </div>
                  <div>
                    <span v-if="item.question.defined == '' ? false : true"
                      >({{ item.question.defined }})</span
                    ><span v-else
                      >({{ item.question.type == 3 ? "判断题" : "" }})</span
                    >
                  </div>
                  <div>({{ item.question.grade }}分)</div>
                </div> -->
              <!-- 文字 -->
              <div
                class="judgesWords"
                v-if="item.question.stem_type == 1 ? true : false"
              >
                <div class="allListStemTitle">
                  <!-- <div class="left">
                      <span></span>
                      <div class="index">{{ index + 1 }}</div>
                      <div class="right-line"></div>
                    </div> -->
                  <div class="allListTitle">
                    <div class="allListLeftborder"></div>
                    <div>
                      <span>{{ index + 1 }}</span>
                    </div>
                    <div>
                      <span v-if="item.question.defined == '' ? false : true"
                        >({{ item.question.defined }})</span
                      ><span v-else>{{
                        item.question.type == 3 ? "判断题" : ""
                      }}</span>
                    </div>
                    <!-- <div>({{ item.question.grade }}分)</div> -->
                  </div>
                  <div class="connction">
                    <div
                      v-if="item.question.collection == 1"
                      @click="clickActive(index)"
                    >
                      <van-icon size="1.5em" color="#ffb41c" name="star" />
                      <span>收藏</span>
                    </div>
                    <div v-else @click="clickActive(index)">
                      <van-icon color="#ccc" name="star" size="1.5em" />
                      <span>收藏</span>
                    </div>

                    <!-- <img
                      @click="sybelBtn(index)"
                      :src="item.biaoji == 0 ? item.sybelImg : item.sybelPath"
                      alt=""
                    />
                    <p>标记</p> -->
                  </div>
                </div>
                <div class="stemBox common-stem">
                  <div class="judgesStem" v-html="item.question.stem"></div>
                  <div
                    v-for="(item1, index1) in item.question.judges"
                    :key="index1"
                    class="judgesSelectList"
                    @click="!item.isShowJx && clickJudges(index, index1)"
                  >
                    <div :class="item1.isSelect ? 'isSelect' : ''"></div>
                    <p v-html="item1.answer"></p>
                  </div>
                  <div class="btn">
                    <div></div>
                    <el-button
                      type="primary"
                      :disabled="!item.makeQuestion"
                      :class="item.isShowJx ? 'activeJx' : ''"
                      @click="clickShowJx(index)"
                      ><i class="el-icon-s-claim"></i>验证答案</el-button
                    >
                  </div>
                  <div class="analysis" v-if="item.isShowJx">
                    <div class="analysisDa">
                      <div class="analysisDaQuery">
                        <div class="nide" style="display: inline-block">
                          <div>你的答案:</div>
                          <span
                            class="youanswer"
                            v-for="(item1, index1) in item.question.judges"
                            :key="index1"
                            v-show="item1.isSelect"
                            >{{ item1.answer }}
                            <i
                              class="iconfont icon-zhengque1"
                              style="color: #25c17c; font-size: 20px"
                              v-if="
                                item1.yes == 1 && item1.isSelect ? true : false
                              "
                            ></i
                            ><i
                              class="iconfont icon-cuowu"
                              style="color: #f4433c; font-size: 20px"
                              v-if="
                                item1.yes == 0 && item1.isSelect ? true : false
                              "
                            ></i>
                          </span>
                        </div>
                        <div class="daan" style="display: inline-block">
                          <div>参考答案:</div>
                          <span
                            v-for="(item1, index1) in item.question.judges"
                            :key="index1"
                            v-show="item1.yes == 1 ? true : false"
                          >
                            {{ item1.answer }}
                          </span>
                        </div>
                      </div>
                      <div class="jiexi">
                        <div>解析：</div>
                        <div
                          class="praticeAnalysis"
                          v-html="item.question.analysis"
                          v-if="item.question.analysis == '' ? false : true"
                        ></div>
                        <span
                          class="praticeAnalysis"
                          v-if="item.question.analysis == '' ? true : false"
                          >暂无</span
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- 音频 -->
              <div
                class="judgesAudio"
                v-if="item.question.stem_type == 2 ? true : false"
              >
                <div class="allListStemTitle">
                  <div class="allListTitle">
                    <div class="allListLeftborder"></div>
                    <div>
                      <span>{{ index + 1 }}</span>
                    </div>
                    <div>
                      <span v-if="item.question.defined == '' ? false : true"
                        >({{ item.question.defined }})</span
                      ><span v-else>{{
                        item.question.type == 3 ? "判断题" : ""
                      }}</span>
                    </div>
                    <!-- <div>({{ item.question.grade }}分)</div> -->
                  </div>

                  <div class="connction">
                    <div
                      v-if="item.question.collection == 1"
                      @click="clickActive(index)"
                    >
                      <van-icon size="1.5em" color="#ffb41c" name="star" />
                      <span>收藏</span>
                    </div>
                    <div v-else @click="clickActive(index)">
                      <van-icon color="#ccc" name="star" size="1.5em" />
                      <span>收藏</span>
                    </div>

                    <!-- <img
                      @click="sybelBtn(index)"
                      :src="item.biaoji == 0 ? item.sybelImg : item.sybelPath"
                      alt=""
                    />
                    <p>标记</p> -->
                  </div>
                </div>
                <div class="stemBox common-stem">
                  <div class="audioBox" v-if="item.question.file">
                    <div class="btnback" v-if="item.question.file">
                      <div class="back" @click="clickSnd(-15)">
                        <img src="../../assets/image/kuaijin1.png" alt="" />
                      </div>
                      <div>{{ item.question.file.filename }}</div>
                      <div class="forward" @click="clickSnd(15)">
                        <img src="../../assets/image/kuaijin2.png" alt="" />
                      </div>
                    </div>
                    <audio
                      v-if="item.question.file"
                      controls
                      controlslist="nodownload"
                    >
                      <source
                        :src="item.question.file.playpath"
                        type="audio/mp3"
                      />
                      您的浏览器不支持 HTML5 audio 标签。
                    </audio>
                  </div>

                  <div class="judgesStem" v-html="item.question.stem"></div>

                  <div
                    v-for="(item1, index1) in item.question.judges"
                    :key="index1"
                    class="judgesSelectList"
                    @click="!item.isShowJx && clickJudges(index, index1)"
                  >
                    <div :class="item1.isSelect ? 'isSelect' : ''"></div>
                    <p v-html="item1.answer"></p>
                  </div>
                  <div class="btn">
                    <div></div>
                    <el-button
                      type="primary"
                      :disabled="!item.makeQuestion"
                      :class="item.isShowJx ? 'activeJx' : ''"
                      @click="clickShowJx(index)"
                      ><i class="el-icon-s-claim"></i>验证答案</el-button
                    >
                  </div>
                  <div class="analysis" v-if="item.isShowJx">
                    <div class="analysisDa">
                      <div>
                        <div class="nide" style="display: inline-block">
                          <div>你的答案:</div>
                          <span
                            v-for="(item1, index1) in item.question.judges"
                            :key="index1"
                            v-show="item1.isSelect"
                          >
                            <i
                              class="iconfont icon-zhengque1"
                              style="color: #25c17c; font-size: 20px"
                              v-if="
                                item1.yes == 1 && item1.isSelect ? true : false
                              "
                            ></i
                            ><i
                              class="iconfont icon-cuowu"
                              style="color: #f4433c; font-size: 20px"
                              v-if="
                                item1.yes == 0 && item1.isSelect ? true : false
                              "
                            ></i>
                            {{ item1.answer }}
                          </span>
                        </div>
                        <div
                          class="daan"
                          style="display: inline-block; margin-left: 20px"
                        >
                          <h4>参考答案:</h4>
                          <span
                            v-for="(item1, index1) in item.question.judges"
                            :key="index1"
                            v-show="item1.yes == 1 ? true : false"
                          >
                            {{ item1.answer }}
                          </span>
                        </div>
                      </div>
                      <div class="jiexi">
                        <div>解析：</div>
                        <div
                          class="praticeAnalysis"
                          v-if="item.question.analysis == '' ? false : true"
                          v-html="item.question.analysis"
                        ></div>
                        <span
                          class="praticeAnalysis"
                          v-if="item.question.analysis == '' ? true : false"
                          >暂无</span
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- 视频 -->
              <div
                class="judgesVideo"
                v-if="item.question.stem_type == 3 ? true : false"
              >
                <div class="allListStemTitle">
                  <div class="allListTitle">
                    <div class="allListLeftborder"></div>
                    <div>
                      <span>{{ index + 1 }}</span>
                    </div>
                    <div>
                      <span v-if="item.question.defined == '' ? false : true"
                        >({{ item.question.defined }})</span
                      ><span v-else>{{
                        item.question.type == 3 ? "判断题" : ""
                      }}</span>
                    </div>
                    <!-- <div>({{ item.question.grade }}分)</div> -->
                  </div>

                  <div class="connction">
                    <div
                      v-if="item.question.collection == 1"
                      @click="clickActive(index)"
                    >
                      <van-icon size="1.5em" color="#ffb41c" name="star" />
                      <span>收藏</span>
                    </div>
                    <div v-else @click="clickActive(index)">
                      <van-icon color="#ccc" name="star" size="1.5em" />
                      <span>收藏</span>
                    </div>

                    <!-- <img
                      @click="sybelBtn(index)"
                      :src="item.biaoji == 0 ? item.sybelImg : item.sybelPath"
                      alt=""
                    />
                    <p>标记</p> -->
                  </div>
                </div>
                <div class="stemBox common-stem">
                  <video
                    :src="item.question.file.playpath"
                    controls="controls"
                  ></video>
                  <div class="judgesStem" v-html="item.question.stem"></div>

                  <div
                    v-for="(item1, index1) in item.question.judges"
                    :key="index1"
                    class="judgesSelectList"
                    @click="!item.isShowJx && clickJudges(index, index1)"
                  >
                    <div :class="item1.isSelect ? 'isSelect' : ''"></div>
                    <p v-html="item1.answer"></p>
                  </div>
                  <div class="btn">
                    <div></div>
                    <el-button
                      type="primary"
                      :disabled="!item.makeQuestion"
                      :class="item.isShowJx ? 'activeJx' : ''"
                      @click="clickShowJx(index)"
                      ><i class="el-icon-s-claim"></i>验证答案</el-button
                    >
                  </div>
                  <div class="analysis" v-if="item.isShowJx">
                    <div class="analysisDa">
                      <div>
                        <div class="nide" style="display: inline-block">
                          <div>你的答案:</div>
                          <span
                            v-for="(item1, index1) in item.question.judges"
                            :key="index1"
                            v-show="item1.isSelect"
                          >
                            <i
                              class="iconfont icon-zhengque1"
                              style="color: #25c17c; font-size: 20px"
                              v-if="
                                item1.yes == 1 && item1.isSelect ? true : false
                              "
                            ></i
                            ><i
                              class="iconfont icon-cuowu"
                              style="color: #f4433c; font-size: 20px"
                              v-if="
                                item1.yes == 0 && item1.isSelect ? true : false
                              "
                            ></i>
                            {{ item1.answer }}
                          </span>
                        </div>
                        <div
                          class="daan"
                          style="display: inline-block; margin-left: 20px"
                        >
                          <h4>参考答案:</h4>
                          <span
                            v-for="(item1, index1) in item.question.judges"
                            :key="index1"
                            v-show="item1.yes == 1 ? true : false"
                          >
                            {{ item1.answer }}
                          </span>
                        </div>
                      </div>
                      <div class="jiexi">
                        <div>解析：</div>
                        <div
                          class="praticeAnalysis"
                          v-html="item.question.analysis"
                          v-if="item.question.analysis == '' ? false : true"
                        ></div>
                        <span
                          class="praticeAnalysis"
                          v-if="item.question.analysis == '' ? true : false"
                          >暂无</span
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- 多元填空 -->
            <div
              class="blankChoice"
              v-if="item.question.type == 6 ? true : false"
            >
              <!-- 文字 -->
              <div
                class="blankWords"
                v-if="item.question.stem_type == 1 ? true : false"
              >
                <div class="allListStemTitle">
                  <div class="allListTitle">
                    <div class="allListLeftborder"></div>
                    <div>
                      <span>{{ index + 1 }}</span>
                    </div>
                    <div>
                      <span v-if="item.question.defined == '' ? false : true"
                        >({{ item.question.defined }})</span
                      ><span v-else>{{
                        item.question.type == 6 ? "多元填空" : ""
                      }}</span>
                    </div>
                    <!-- <div>({{ item.question.grade }}分)</div> -->
                  </div>

                  <div class="connction">
                    <div
                      v-if="item.question.collection == 1"
                      @click="clickActive(index)"
                    >
                      <van-icon size="1.5em" color="#ffb41c" name="star" />
                      <span>收藏</span>
                    </div>
                    <div v-else @click="clickActive(index)">
                      <van-icon color="#ccc" name="star" size="1.5em" />
                      <span>收藏</span>
                    </div>

                    <!-- <img
                      @click="sybelBtn(index)"
                      :src="item.biaoji == 0 ? item.sybelImg : item.sybelPath"
                      alt=""
                    />
                    <p>标记</p> -->
                  </div>
                </div>
                <div class="stemBox common-stem">
                  <div
                    @input="!item.isShowJx && clickInput($event, index)"
                    class="blankStem"
                    v-html="item.question.stem"
                  ></div>
                  <div>
                    <div class="btn">
                      <div></div>
                      <el-button
                        :disabled="!item.makeQuestion"
                        type="primary"
                        :class="item.isShowJx ? 'activeJx' : ''"
                        @click="clickShowJx(index)"
                      >
                        <i class="el-icon-s-claim"></i>验证答案</el-button
                      >
                    </div>
                    <div class="analysis" v-if="item.isShowJx">
                      <div class="daan">
                        <div
                          v-for="(item1, index1) in item.question
                            .multiple_clozes"
                          :key="index1"
                        >
                          <div>
                            <div>第({{ index1 + 1 }})空</div>
                            <!-- {{ index + 1 }}: -->
                            <div class="blankChoice-answer">
                              <div class="nide">
                                你的答案:

                                <span v-if="item.input[index1] != ''"
                                  >{{ item.input[index1] }}
                                  <!-- <i
                                class="iconfont icon-zhengque1"
                                style="color: #25c17c; font-size: 20px"
                                v-if="item1.succ"
                              ></i
                              ><i
                                class="iconfont icon-cuowu"
                                style="color: #f4433c; font-size: 20px"
                                v-if="!item1.succ"
                              ></i
                            > -->
                                </span>
                                <span v-else>未作答</span>
                              </div>
                              <div class="reference">
                                参考答案:
                                <div>
                                  <div
                                    v-for="(item2, index2) in item1.answers"
                                    :key="index2"
                                  >
                                    {{ item2.answer }}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div>
                            小题解析：<span class="praticeAnalysis">{{
                              item1.analysis
                            }}</span>
                            <span
                              class="praticeAnalysis"
                              v-if="item1.analysis == '' ? true : false"
                              >暂无</span
                            >
                          </div>
                        </div>
                      </div>
                      <div class="jiexi" v-if="item.question.analysis">
                        <div>解析:</div>
                        <div
                          class="praticeAnalysis"
                          v-html="item.question.analysis"
                        ></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- 音频 -->
              <div
                class="blankAudio"
                v-if="item.question.stem_type == 2 ? true : false"
              >
                <div class="allListStemTitle">
                  <div class="allListTitle">
                    <div class="allListLeftborder"></div>
                    <div>
                      <span>{{ index + 1 }}</span>
                    </div>
                    <div>
                      <span v-if="item.question.defined == '' ? false : true"
                        >({{ item.question.defined }})</span
                      ><span v-else
                        >({{ item.question.type == 6 ? "多元填空" : "" }})</span
                      >
                    </div>
                    <!-- <div>({{ item.question.grade }}分)</div> -->
                  </div>
                  <div class="common-center">
                    <div></div>
                    <span class="fontweight">答题卡</span>
                  </div>
                  <div class="connction">
                    <div
                      v-if="item.question.collection == 1"
                      @click="clickActive(index)"
                    >
                      <van-icon size="1.5em" color="#ffb41c" name="star" />
                      <span>收藏</span>
                    </div>
                    <div v-else @click="clickActive(index)">
                      <van-icon color="#ccc" name="star" size="1.5em" />
                      <span>收藏</span>
                    </div>

                    <!-- <img
                      @click="sybelBtn(index)"
                      :src="item.biaoji == 0 ? item.sybelImg : item.sybelPath"
                      alt=""
                    />
                    <p>标记</p> -->
                  </div>
                </div>
                <div class="stemBox common-stem">
                  <!-- <div class="stem-left"> -->
                  <div class="audioBox" v-if="item.question.file">
                    <div class="btnback" v-if="item.question.file">
                      <div class="back" @click="clickSnd(-15)">
                        <img src="../../assets/image/kuaijin1.png" alt="" />
                      </div>
                      <div>{{ item.question.file.filename }}</div>
                      <div class="forward" @click="clickSnd(15)">
                        <img src="../../assets/image/kuaijin2.png" alt="" />
                      </div>
                    </div>
                    <audio
                      v-if="item.question.file"
                      controls
                      controlslist="nodownload"
                    >
                      <source
                        :src="item.question.file.playpath"
                        type="audio/mp3"
                      />
                      您的浏览器不支持 HTML5 audio 标签。
                    </audio>
                  </div>
                  <!-- </div> -->
                  <!-- <div class="stem-center"></div> -->
                  <!-- <div class="stem-right"> -->
                  <div
                    @input="!item.isShowJx && clickInput($event, index)"
                    class="blankStem"
                    v-html="item.question.stem"
                  ></div>
                  <!-- </div> -->
                  <div>
                    <div class="btn">
                      <div></div>
                      <el-button
                        :disabled="!item.makeQuestion"
                        type="primary"
                        :class="item.isShowJx ? 'activeJx' : ''"
                        @click="clickShowJx(index)"
                        ><i class="el-icon-s-claim"></i>验证答案</el-button
                      >
                    </div>
                    <div class="analysis" v-if="item.isShowJx">
                      <div class="daan">
                        <div
                          v-for="(item1, index1) in item.question
                            .multiple_clozes"
                          :key="index1"
                        >
                          <div>
                            <div>第({{ index1 + 1 }})空</div>
                            <!-- {{ index + 1 }}: -->
                            <div class="blankChoice-answer">
                              <div class="nide">
                                你的答案:

                                <span v-if="item.input[index1] != ''"
                                  >{{ item.input[index1] }}
                                  <!-- <i
                                class="iconfont icon-zhengque1"
                                style="color: #25c17c; font-size: 20px"
                                v-if="item1.succ"
                              ></i
                              ><i
                                class="iconfont icon-cuowu"
                                style="color: #f4433c; font-size: 20px"
                                v-if="!item1.succ"
                              ></i
                            > -->
                                </span>
                                <span v-else>未作答</span>
                              </div>
                              <div class="reference">
                                参考答案:
                                <div>
                                  <div
                                    v-for="(item2, index2) in item1.answers"
                                    :key="index2"
                                  >
                                    {{ item2.answer }}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div>
                            小题解析：
                            <span class="praticeAnalysis">{{
                              item1.analysis
                            }}</span>
                            <span
                              class="praticeAnalysis"
                              v-if="item1.analysis == '' ? true : false"
                              >暂无</span
                            >
                          </div>
                        </div>
                      </div>
                      <div class="jiexi" v-if="item.question.analysis">
                        <div>解析:</div>
                        <div
                          class="praticeAnalysis"
                          v-if="item.question.analysis == '' ? false : true"
                          v-html="item.question.analysis"
                        ></div>
                        <span
                          class="praticeAnalysis"
                          v-if="item.question.analysis == '' ? true : false"
                          >暂无</span
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- 图片 -->
              <div
                class="blankWords"
                v-if="item.question.stem_type == 4 ? true : false"
              >
                <div class="allListStemTitle">
                  <div class="allListTitle">
                    <div class="allListLeftborder"></div>
                    <div>
                      <span>{{ index + 1 }}</span>
                    </div>
                    <div>
                      <span v-if="item.question.defined == '' ? false : true"
                        >({{ item.question.defined }})</span
                      ><span v-else>{{
                        item.question.type == 6 ? "多元填空" : ""
                      }}</span>
                    </div>
                    <!-- <div>({{ item.question.grade }}分)</div> -->
                  </div>

                  <div class="connction">
                    <div
                      v-if="item.question.collection == 1"
                      @click="clickActive(index)"
                    >
                      <van-icon size="1.5em" color="#ffb41c" name="star" />
                      <span>收藏</span>
                    </div>
                    <div v-else @click="clickActive(index)">
                      <van-icon color="#ccc" name="star" size="1.5em" />
                      <span>收藏</span>
                    </div>

                    <!-- <img
                      @click="sybelBtn(index)"
                      :src="item.biaoji == 0 ? item.sybelImg : item.sybelPath"
                      alt=""
                    />
                    <p>标记</p> -->
                  </div>
                </div>
                <div class="stemBox common-stem">
                  <img
                    @click="clickShowImagePreview(index)"
                    :src="item.question.file.playpath"
                    v-if="item.question.file"
                    alt=""
                  />
                  <!-- :src="item.question.file.playpath" -->
                  <div
                    @input="!item.isShowJx && clickInput($event, index)"
                    class="blankStem"
                    v-html="item.question.stem"
                  ></div>
                  <div>
                    <div class="btn">
                      <div></div>
                      <el-button
                        :disabled="!item.makeQuestion"
                        type="primary"
                        :class="item.isShowJx ? 'activeJx' : ''"
                        @click="clickShowJx(index)"
                      >
                        <i class="el-icon-s-claim"></i>验证答案</el-button
                      >
                    </div>
                    <div class="analysis" v-if="item.isShowJx">
                      <div class="daan">
                        <div
                          v-for="(item1, index1) in item.question
                            .multiple_clozes"
                          :key="index1"
                        >
                          <div>
                            <div>第({{ index1 + 1 }})空</div>
                            <!-- {{ index + 1 }}: -->
                            <div class="blankChoice-answer">
                              <div class="nide">
                                你的答案:

                                <span v-if="item.input[index1] != ''"
                                  >{{ item.input[index1] }}
                                  <!-- <i
                                class="iconfont icon-zhengque1"
                                style="color: #25c17c; font-size: 20px"
                                v-if="item1.succ"
                              ></i
                              ><i
                                class="iconfont icon-cuowu"
                                style="color: #f4433c; font-size: 20px"
                                v-if="!item1.succ"
                              ></i
                            > -->
                                </span>
                                <span v-else>未作答</span>
                              </div>
                              <div class="reference">
                                参考答案:
                                <div>
                                  <div
                                    v-for="(item2, index2) in item1.answers"
                                    :key="index2"
                                  >
                                    {{ item2.answer }}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div>
                            小题解析：<span class="praticeAnalysis">{{
                              item1.analysis
                            }}</span>
                            <span
                              class="praticeAnalysis"
                              v-if="item1.analysis == '' ? true : false"
                              >暂无</span
                            >
                          </div>
                        </div>
                      </div>
                      <div class="jiexi" v-if="item.question.analysis">
                        <div>解析:</div>
                        <div
                          class="praticeAnalysis"
                          v-html="item.question.analysis"
                        ></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- 阅读题 -->
            <div
              class="readingChoice"
              v-if="item.question.type == 9 ? true : false"
            >
              <!-- <div class="allListTitle">
                  <div class="allListLeftborder"></div>
                  <div>
                    PRUEBA <span>{{ index + 1 }}</span>
                  </div>
                  <div>
                    <span v-if="item.question.defined == '' ? false : true"
                      >({{ item.question.defined }})</span
                    ><span v-else
                      >({{ item.question.type == 9 ? "阅读题" : "" }})</span
                    >
                  </div>
                  <div>({{ item.question.grade }}分)</div>
                </div> -->
              <!-- 文字 -->
              <div
                class="readingWords"
                v-if="item.question.stem_type == 1 ? true : false"
              >
                <div class="allListStemTitle">
                  <!-- <div class="left">
                      <span></span>
                      <div class="index">{{ index + 1 }}</div>
                      <div class="right-line"></div>
                    </div> -->
                  <div class="allListTitle">
                    <div class="allListLeftborder"></div>
                    <div>
                      <span>{{ index + 1 }}</span>
                    </div>
                    <div>
                      <span v-if="item.question.defined == '' ? false : true"
                        >({{ item.question.defined }})</span
                      ><span v-else>{{
                        item.question.type == 9 ? "阅读题" : ""
                      }}</span>
                    </div>
                    <!-- <div>({{ item.question.grade }}分)</div> -->
                  </div>
                  <div class="common-center">
                    <div></div>
                    <span class="fontweight">答题卡</span>
                  </div>

                  <div class="connction">
                    <div
                      v-if="item.question.collection == 1"
                      @click="clickActive(index)"
                    >
                      <van-icon size="1.5em" color="#ffb41c" name="star" />
                      <span>收藏</span>
                    </div>
                    <div v-else @click="clickActive(index)">
                      <van-icon color="#ccc" name="star" size="1.5em" />
                      <span>收藏</span>
                    </div>

                    <!-- <img
                      @click="sybelBtn(index)"
                      :src="item.biaoji == 0 ? item.sybelImg : item.sybelPath"
                      alt=""
                    />
                    <p>标记</p> -->
                  </div>
                </div>

                <div class="showMax" @click="showMax(index)">材料全屏</div>
                <div class="stemBox common-stem2">
                  <div class="stem-left">
                    <div class="readingStem" v-html="item.question.stem"></div>
                  </div>
                  <div class="stem-center"></div>
                  <div class="stem-right">
                    <div
                      class="readTopic"
                      v-for="(item1, index1) in item.question.reads"
                      :key="index1"
                    >
                      <div class="readingStem">
                        {{ index1 + 1 }}.{{
                          item1.question.stem == "null"
                            ? ""
                            : item1.question.stem
                        }}<span>({{ item1.question.grade }}分)</span>
                      </div>

                      <div
                        class="readingSelectList"
                        v-for="(item2, index2) in item1.question.single_choices"
                        :key="index2"
                        @click="
                          !item1.isShowJx && clickReading(index, index1, index2)
                        "
                      >
                        <div :class="item2.isSelect ? 'isSelect' : ''">
                          {{ listSum[index2] }}
                        </div>
                        <p v-html="item2.answer"></p>
                      </div>
                      <div class="btn">
                        <div></div>
                        <el-button
                          type="primary"
                          :disabled="!item1.makeQuestion"
                          :class="item1.isShowJx ? 'activeJx' : ''"
                          @click="clickShowJxRed(index, index1)"
                          ><i class="el-icon-s-claim"></i>验证答案</el-button
                        >
                      </div>
                      <div v-show="item1.isShowJx">
                        <div class="analysisDa">
                          <div>
                            <div class="nide" style="display: inline-block">
                              <div>你的答案:</div>

                              <span
                                class="youanswer"
                                v-for="(item2, index2) in item1.question
                                  .single_choices"
                                :key="index2"
                                v-show="item2.isSelect"
                              >
                                {{ listSum[index2] }}
                                <i
                                  class="iconfont icon-zhengque1"
                                  style="color: #25c17c; font-size: 20px"
                                  v-if="
                                    item2.yes == 1 && item2.isSelect
                                      ? true
                                      : false
                                  "
                                ></i
                                ><i
                                  class="iconfont icon-cuowu"
                                  style="color: #f4433c; font-size: 20px"
                                  v-if="
                                    item2.yes == 0 && item2.isSelect
                                      ? true
                                      : false
                                  "
                                ></i>
                              </span>
                            </div>
                            <div class="daan" style="display: inline-block">
                              <div>参考答案:</div>
                              <span
                                v-for="(item2, index2) in item1.question
                                  .single_choices"
                                :key="index2"
                                v-show="item2.yes == 1 ? true : false"
                              >
                                {{ listSum[index2] }}
                              </span>
                            </div>
                          </div>
                          <div class="jiexi">
                            <div>解析：</div>
                            <div
                              class="praticeAnalysis"
                              v-html="item1.question.analysis"
                            ></div>
                            <span
                              class="praticeAnalysis"
                              v-if="
                                item1.question.analysis == '' ? true : false
                              "
                              >暂无</span
                            >
                          </div>
                        </div>
                      </div>
                    </div>
                    <div style="margin-top: 20px">
                      <div></div>
                      <el-button
                        :disabled="!item.makeQuestion"
                        type="primary"
                        @click="clickShowJx(index)"
                        :class="item.isShowJx ? 'activeJx' : ''"
                        >查看解析</el-button
                      >
                    </div>
                    <div class="bigjiexi" v-if="item.isShowJx">
                      <div style="color: black">阅读解析:</div>
                      <div
                        class="praticeAnalysis"
                        v-html="item.question.analysis"
                        v-if="item.question.analysis == '' ? false : true"
                      ></div>
                      <span
                        class="praticeAnalysis"
                        v-if="item.question.analysis == '' ? true : false"
                        >暂无</span
                      >
                    </div>
                  </div>
                </div>
              </div>
              <!-- 音频 -->
              <div
                class="readingAudio"
                v-if="item.question.stem_type == 2 ? true : false"
              >
                <div class="allListStemTitle">
                  <!-- <div class="left">
                      <span></span>
                      <div class="index">{{ index + 1 }}</div>
                      <div class="right-line"></div>
                    </div> -->
                  <div class="allListTitle">
                    <div class="allListLeftborder"></div>
                    <div>
                      <span>{{ index + 1 }}</span>
                    </div>
                    <div>
                      <span v-if="item.question.defined == '' ? false : true"
                        >({{ item.question.defined }})</span
                      ><span v-else>{{
                        item.question.type == 9 ? "阅读题" : ""
                      }}</span>
                    </div>
                    <!-- <div>({{ item.question.grade }}分)</div> -->
                  </div>
                  <div class="common-center">
                    <div></div>
                    <span class="fontweight">答题卡</span>
                  </div>

                  <div class="connction">
                    <div
                      v-if="item.question.collection == 1"
                      @click="clickActive(index)"
                    >
                      <van-icon size="1.5em" color="#ffb41c" name="star" />
                      <span>收藏</span>
                    </div>
                    <div v-else @click="clickActive(index)">
                      <van-icon color="#ccc" name="star" size="1.5em" />
                      <span>收藏</span>
                    </div>

                    <!-- <img
                      @click="sybelBtn(index)"
                      :src="item.biaoji == 0 ? item.sybelImg : item.sybelPath"
                      alt=""
                    />
                    <p>标记</p> -->
                  </div>
                </div>
                <div class="showMax" @click="showMax(index)">材料全屏</div>
                <div class="stemBox common-stem2">
                  <div class="stem-left">
                    <div class="audioBox" v-if="item.question.file">
                      <div class="btnback" v-if="item.question.file">
                        <div class="back" @click="clickSnd(-15)">
                          <img src="../../assets/image/kuaijin1.png" alt="" />
                        </div>
                        <div>{{ item.question.file.filename }}</div>
                        <div class="forward" @click="clickSnd(15)">
                          <img src="../../assets/image/kuaijin2.png" alt="" />
                        </div>
                      </div>
                      <audio
                        v-if="item.question.file"
                        controls
                        controlslist="nodownload"
                      >
                        <source
                          :src="item.question.file.playpath"
                          type="audio/mp3"
                        />
                        您的浏览器不支持 HTML5 audio 标签。
                      </audio>
                    </div>
                    <div class="readingStem" v-html="item.question.stem"></div>
                  </div>
                  <div class="stem-center"></div>
                  <div class="stem-right">
                    <div
                      class="readTopic"
                      v-for="(item1, index1) in item.question.reads"
                      :key="index1"
                    >
                      <div class="readingStem">
                        {{ index1 + 1 }}.{{
                          item1.question.stem == "null"
                            ? ""
                            : item1.question.stem
                        }}<span>({{ item1.question.grade }}分)</span>
                      </div>

                      <div
                        class="readingSelectList"
                        v-for="(item2, index2) in item1.question.single_choices"
                        :key="index2"
                        @click="
                          !item1.isShowJx && clickReading(index, index1, index2)
                        "
                      >
                        <div :class="item2.isSelect ? 'isSelect' : ''">
                          {{ listSum[index2] }}
                        </div>
                        <p v-html="item2.answer"></p>
                      </div>
                      <div class="btn">
                        <div></div>
                        <el-button
                          type="primary"
                          :disabled="!item1.makeQuestion"
                          :class="item1.isShowJx ? 'activeJx' : ''"
                          @click="clickShowJxRed(index, index1)"
                          ><i class="el-icon-s-claim"></i>验证答案</el-button
                        >
                      </div>
                      <div v-show="item1.isShowJx">
                        <div class="analysisDa">
                          <div>
                            <div class="nide" style="display: inline-block">
                              <div>你的答案:</div>

                              <span
                                class="youanswer"
                                v-for="(item2, index2) in item1.question
                                  .single_choices"
                                :key="index2"
                                v-show="item2.isSelect"
                              >
                                {{ listSum[index2] }}
                                <i
                                  class="iconfont icon-zhengque1"
                                  style="color: #25c17c; font-size: 20px"
                                  v-if="
                                    item2.yes == 1 && item2.isSelect
                                      ? true
                                      : false
                                  "
                                ></i
                                ><i
                                  class="iconfont icon-cuowu"
                                  style="color: #f4433c; font-size: 20px"
                                  v-if="
                                    item2.yes == 0 && item2.isSelect
                                      ? true
                                      : false
                                  "
                                ></i>
                              </span>
                            </div>
                            <div class="daan" style="display: inline-block">
                              <div>参考答案:</div>
                              <span
                                v-for="(item2, index2) in item1.question
                                  .single_choices"
                                :key="index2"
                                v-show="item2.yes == 1 ? true : false"
                              >
                                {{ listSum[index2] }}
                              </span>
                            </div>
                          </div>
                          <div class="jiexi">
                            <div>解析：</div>
                            <div
                              class="praticeAnalysis"
                              v-html="item1.question.analysis"
                            ></div>
                            <span
                              class="praticeAnalysis"
                              v-if="
                                item1.question.analysis == '' ? true : false
                              "
                              >暂无</span
                            >
                          </div>
                        </div>
                      </div>
                    </div>
                    <div style="margin-top: 20px">
                      <div></div>
                      <el-button
                        :disabled="!item.makeQuestion"
                        type="primary"
                        @click="clickShowJx(index)"
                        :class="item.isShowJx ? 'activeJx' : ''"
                        >查看解析</el-button
                      >
                    </div>
                    <div class="bigjiexi" v-if="item.isShowJx">
                      <div style="color: black">阅读解析:</div>
                      <div
                        class="praticeAnalysis"
                        v-html="item.question.analysis"
                        v-if="item.question.analysis == '' ? false : true"
                      ></div>
                      <span
                        class="praticeAnalysis"
                        v-if="item.question.analysis == '' ? true : false"
                        >暂无</span
                      >
                    </div>
                  </div>
                </div>
              </div>
              <!-- 视频 -->
              <div
                class="readingVideo"
                v-if="item.question.stem_type == 3 ? true : false"
              >
                <div class="allListStemTitle">
                  <!-- <div class="left">
                      <span></span>
                      <div class="index">{{ index + 1 }}</div>
                      <div class="right-line"></div>
                    </div> -->
                  <div class="allListTitle">
                    <div class="allListLeftborder"></div>
                    <div>
                      <span>{{ index + 1 }}</span>
                    </div>
                    <div>
                      <span v-if="item.question.defined == '' ? false : true"
                        >({{ item.question.defined }})</span
                      ><span v-else>{{
                        item.question.type == 9 ? "阅读题" : ""
                      }}</span>
                    </div>
                    <!-- <div>({{ item.question.grade }}分)</div> -->
                  </div>
                  <div class="common-center">
                    <div></div>
                    <span class="fontweight">答题卡</span>
                  </div>

                  <div class="connction">
                    <div
                      v-if="item.question.collection == 1"
                      @click="clickActive(index)"
                    >
                      <van-icon size="1.5em" color="#ffb41c" name="star" />
                      <span>收藏</span>
                    </div>
                    <div v-else @click="clickActive(index)">
                      <van-icon color="#ccc" name="star" size="1.5em" />
                      <span>收藏</span>
                    </div>

                    <!-- <img
                      @click="sybelBtn(index)"
                      :src="item.biaoji == 0 ? item.sybelImg : item.sybelPath"
                      alt=""
                    />
                    <p>标记</p> -->
                  </div>
                </div>
                <div class="showMax" @click="showMax(index)">材料全屏</div>
                <div class="stemBox common-stem2">
                  <div class="stem-left">
                    <video
                      :src="item.question.file.playpath"
                      controls="controls"
                    ></video>
                    <div class="readingStem" v-html="item.question.stem"></div>
                  </div>
                  <div class="stem-line"></div>
                  <div class="stem-right">
                    <div
                      class="readTopic"
                      v-for="(item1, index1) in item.question.reads"
                      :key="index1"
                    >
                      <div class="readingStem">
                        {{ index1 + 1 }}.{{
                          item1.question.stem == "null"
                            ? ""
                            : item1.question.stem
                        }}<span>({{ item1.question.grade }}分)</span>
                      </div>

                      <div
                        class="readingSelectList"
                        v-for="(item2, index2) in item1.question.single_choices"
                        :key="index2"
                        @click="
                          !item1.isShowJx && clickReading(index, index1, index2)
                        "
                      >
                        <div :class="item2.isSelect ? 'isSelect' : ''">
                          {{ listSum[index2] }}
                        </div>
                        <p v-html="item2.answer"></p>
                      </div>
                      <div class="btn">
                        <div></div>
                        <el-button
                          type="primary"
                          :disabled="!item1.makeQuestion"
                          :class="item1.isShowJx ? 'activeJx' : ''"
                          @click="clickShowJxRed(index, index1)"
                          ><i class="el-icon-s-claim"></i>验证答案</el-button
                        >
                      </div>
                      <div v-show="item1.isShowJx">
                        <div class="analysisDa">
                          <div>
                            <div class="nide" style="display: inline-block">
                              <div>你的答案:</div>

                              <span
                                class="youanswer"
                                v-for="(item2, index2) in item1.question
                                  .single_choices"
                                :key="index2"
                                v-show="item2.isSelect"
                              >
                                {{ listSum[index2] }}
                                <i
                                  class="iconfont icon-zhengque1"
                                  style="color: #25c17c; font-size: 20px"
                                  v-if="
                                    item2.yes == 1 && item2.isSelect
                                      ? true
                                      : false
                                  "
                                ></i
                                ><i
                                  class="iconfont icon-cuowu"
                                  style="color: #f4433c; font-size: 20px"
                                  v-if="
                                    item2.yes == 0 && item2.isSelect
                                      ? true
                                      : false
                                  "
                                ></i>
                              </span>
                            </div>
                            <div class="daan" style="display: inline-block">
                              <div>参考答案:</div>
                              <span
                                v-for="(item2, index2) in item1.question
                                  .single_choices"
                                :key="index2"
                                v-show="item2.yes == 1 ? true : false"
                              >
                                {{ listSum[index2] }}
                              </span>
                            </div>
                          </div>
                          <div class="jiexi">
                            <div>解析：</div>
                            <div
                              class="praticeAnalysis"
                              v-html="item1.question.analysis"
                            ></div>
                            <span
                              class="praticeAnalysis"
                              v-if="
                                item1.question.analysis == '' ? true : false
                              "
                              >暂无</span
                            >
                          </div>
                        </div>
                      </div>
                    </div>
                    <div style="margin-top: 20px">
                      <div></div>
                      <el-button
                        :disabled="!item.makeQuestion"
                        type="primary"
                        @click="clickShowJx(index)"
                        :class="item.isShowJx ? 'activeJx' : ''"
                        >查看解析</el-button
                      >
                    </div>
                    <div class="bigjiexi" v-if="item.isShowJx">
                      <div style="color: black">阅读解析:</div>
                      <div
                        class="praticeAnalysis"
                        v-html="item.question.analysis"
                        v-if="item.question.analysis == '' ? false : true"
                      ></div>
                      <span
                        class="praticeAnalysis"
                        v-if="item.question.analysis == '' ? true : false"
                        >暂无</span
                      >
                    </div>
                  </div>
                </div>
              </div>
              <!-- 图片 -->
              <div
                class="readingVideo"
                v-if="item.question.stem_type == 4 ? true : false"
              >
                <div class="allListStemTitle">
                  <div class="allListTitle">
                    <div class="allListLeftborder"></div>
                    <div>
                      <span>{{ index + 1 }}</span>
                    </div>
                    <div>
                      <span v-if="item.question.defined == '' ? false : true"
                        >({{ item.question.defined }})</span
                      ><span v-else>{{
                        item.question.type == 9 ? "阅读题" : ""
                      }}</span>
                    </div>
                    <!-- <div>({{ item.question.grade }}分)</div> -->
                  </div>
                  <div class="common-center">
                    <div></div>
                    <span class="fontweight">答题卡</span>
                  </div>

                  <div class="connction">
                    <div
                      v-if="item.question.collection == 1"
                      @click="clickActive(index)"
                    >
                      <van-icon size="1.5em" color="#ffb41c" name="star" />
                      <span>收藏</span>
                    </div>
                    <div v-else @click="clickActive(index)">
                      <van-icon color="#ccc" name="star" size="1.5em" />
                      <span>收藏</span>
                    </div>

                    <!-- <img
                      @click="sybelBtn(index)"
                      :src="item.biaoji == 0 ? item.sybelImg : item.sybelPath"
                      alt=""
                    />
                    <p>标记</p> -->
                  </div>
                </div>
                <div class="showMax" @click="showMax(index)">材料全屏</div>
                <div class="stemBox common-stem2">
                  <div class="stem-left">
                    <img
                      :src="item.question.file.playpath"
                      alt=""
                      @click="clickShowImagePreview(index)"
                      v-if="item.question.file"
                    />
                    <div class="readingStem" v-html="item.question.stem"></div>
                  </div>
                  <div class="stem-center"></div>
                  <div class="stem-right">
                    <div
                      class="readTopic"
                      v-for="(item1, index1) in item.question.reads"
                      :key="index1"
                    >
                      <div class="readingStem">
                        {{ index1 + 1 }}.{{
                          item1.question.stem == "null"
                            ? ""
                            : item1.question.stem
                        }}<span>({{ item1.question.grade }}分)</span>
                      </div>

                      <div
                        class="readingSelectList"
                        v-for="(item2, index2) in item1.question.single_choices"
                        :key="index2"
                        @click="
                          !item1.isShowJx && clickReading(index, index1, index2)
                        "
                      >
                        <div :class="item2.isSelect ? 'isSelect' : ''">
                          {{ listSum[index2] }}
                        </div>
                        <p v-html="item2.answer"></p>
                      </div>
                      <div class="btn">
                        <div></div>
                        <el-button
                          type="primary"
                          :disabled="!item1.makeQuestion"
                          :class="item1.isShowJx ? 'activeJx' : ''"
                          @click="clickShowJxRed(index, index1)"
                          ><i class="el-icon-s-claim"></i>验证答案</el-button
                        >
                      </div>
                      <div v-show="item1.isShowJx">
                        <div class="analysisDa">
                          <div>
                            <div class="nide" style="display: inline-block">
                              <div>你的答案:</div>

                              <span
                                class="youanswer"
                                v-for="(item2, index2) in item1.question
                                  .single_choices"
                                :key="index2"
                                v-show="item2.isSelect"
                              >
                                {{ listSum[index2] }}
                                <i
                                  class="iconfont icon-zhengque1"
                                  style="color: #25c17c; font-size: 20px"
                                  v-if="
                                    item2.yes == 1 && item2.isSelect
                                      ? true
                                      : false
                                  "
                                ></i
                                ><i
                                  class="iconfont icon-cuowu"
                                  style="color: #f4433c; font-size: 20px"
                                  v-if="
                                    item2.yes == 0 && item2.isSelect
                                      ? true
                                      : false
                                  "
                                ></i>
                              </span>
                            </div>
                            <div class="daan" style="display: inline-block">
                              <div>参考答案:</div>
                              <span
                                v-for="(item2, index2) in item1.question
                                  .single_choices"
                                :key="index2"
                                v-show="item2.yes == 1 ? true : false"
                              >
                                {{ listSum[index2] }}
                              </span>
                            </div>
                          </div>
                          <div class="jiexi">
                            <div>解析：</div>
                            <div
                              class="praticeAnalysis"
                              v-html="item1.question.analysis"
                            ></div>
                            <span
                              class="praticeAnalysis"
                              v-if="
                                item1.question.analysis == '' ? true : false
                              "
                              >暂无</span
                            >
                          </div>
                        </div>
                      </div>
                    </div>
                    <div style="margin-top: 20px">
                      <div></div>
                      <el-button
                        :disabled="!item.makeQuestion"
                        type="primary"
                        @click="clickShowJx(index)"
                        :class="item.isShowJx ? 'activeJx' : ''"
                        >查看解析</el-button
                      >
                    </div>
                    <div class="bigjiexi" v-if="item.isShowJx">
                      <div style="color: black">阅读解析:</div>
                      <div
                        class="praticeAnalysis"
                        v-if="item.question.analysis == '' ? false : true"
                        v-html="item.question.analysis"
                      ></div>
                      <span
                        class="praticeAnalysis"
                        v-if="item.question.analysis == '' ? true : false"
                        >暂无</span
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- 问答题 -->
            <div
              class="questionsChoice"
              v-if="item.question.type == 4 ? true : false"
            >
              <!-- 文字 -->
              <div
                class="questionsWords"
                v-if="item.question.stem_type == 1 ? true : false"
              >
                <div class="allListStemTitle">
                  <div class="allListTitle">
                    <div class="allListLeftborder"></div>
                    <div>
                      <span>{{ index + 1 }}</span>
                    </div>
                    <div>
                      <span v-if="item.question.defined == '' ? false : true"
                        >({{ item.question.defined }})</span
                      ><span v-else>{{
                        item.question.type == 4 ? "问答题" : ""
                      }}</span>
                    </div>
                    <!-- <div>({{ item.question.grade }}分)</div> -->
                  </div>
                  <div class="common-center">
                    <div></div>
                    <span class="fontweight">答题卡</span>
                  </div>
                  <div class="connction">
                    <div
                      v-if="item.question.collection == 1"
                      @click="clickActive(index)"
                    >
                      <van-icon size="1.5em" color="#ffb41c" name="star" />
                      <span>收藏</span>
                    </div>
                    <div v-else @click="clickActive(index)">
                      <van-icon color="#ccc" name="star" size="1.5em" />
                      <span>收藏</span>
                    </div>

                    <!-- <img
                      @click="sybelBtn(index)"
                      :src="item.biaoji == 0 ? item.sybelImg : item.sybelPath"
                      alt=""
                    />
                    <p>标记</p> -->
                  </div>
                </div>
                <div class="stemBox common-stem2">
                  <div class="stem-left">
                    <div
                      class="questionsStem"
                      v-html="item.question.stem"
                    ></div>
                  </div>
                  <div class="stem-center"></div>
                  <div class="stem-right">
                    <textarea
                      v-show="!item.isShowJx"
                      v-model="item.modelValue"
                      @input="changeText(index)"
                      type="text"
                      :disabled="item.isShowJx"
                      placeholder="请输入答案"
                    />
                    <div class="btn">
                      <div></div>
                      <el-button
                        :disabled="!item.makeQuestion"
                        type="primary"
                        @click="clickShowJx(index)"
                        :class="item.isShowJx ? 'activeJx' : ''"
                        ><i class="el-icon-s-claim"></i>验证答案</el-button
                      >
                    </div>
                    <div v-if="item.isShowJx">
                      <div class="wenda">
                        <div class="nide">
                          <div>你的答案:</div>

                          <div>
                            {{ item.modelValue }}
                          </div>
                        </div>
                        <div class="jiexi">
                          <div>解析:</div>
                          <div
                            class="praticeAnalysis"
                            v-html="item.question.analysis"
                          ></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- 音频 -->
              <div
                class="questionsAudio"
                v-if="item.question.stem_type == 2 ? true : false"
              >
                <div class="allListStemTitle">
                  <div class="allListTitle">
                    <div class="allListLeftborder"></div>
                    <div>
                      <span>{{ index + 1 }}</span>
                    </div>
                    <div>
                      <span v-if="item.question.defined == '' ? false : true"
                        >({{ item.question.defined }})</span
                      ><span v-else
                        >({{ item.question.type == 4 ? "问答题" : "" }})</span
                      >
                    </div>
                    <!-- <div>({{ item.question.grade }}分)</div> -->
                  </div>
                  <div class="common-center">
                    <div></div>
                    <span class="fontweight">答题卡</span>
                  </div>

                  <div class="connction">
                    <div
                      v-if="item.question.collection == 1"
                      @click="clickActive(index)"
                    >
                      <van-icon size="1.5em" color="#ffb41c" name="star" />
                      <span>收藏</span>
                    </div>
                    <div v-else @click="clickActive(index)">
                      <van-icon color="#ccc" name="star" size="1.5em" />
                      <span>收藏</span>
                    </div>

                    <!-- <img
                      @click="sybelBtn(index)"
                      :src="item.biaoji == 0 ? item.sybelImg : item.sybelPath"
                      alt=""
                    />
                    <p>标记</p> -->
                  </div>
                </div>
                <div class="stemBox common-stem2">
                  <div class="stem-left">
                    <div class="audioBox" v-if="item.question.file">
                      <div class="btnback" v-if="item.question.file">
                        <div class="back" @click="clickSnd(-15)">
                          <img src="../../assets/image/kuaijin1.png" alt="" />
                        </div>
                        <div>{{ item.question.file.filename }}</div>
                        <div class="forward" @click="clickSnd(15)">
                          <img src="../../assets/image/kuaijin2.png" alt="" />
                        </div>
                      </div>
                      <audio
                        v-if="item.question.file"
                        controls
                        controlslist="nodownload"
                      >
                        <source
                          :src="item.question.file.playpath"
                          type="audio/mp3"
                        />
                        您的浏览器不支持 HTML5 audio 标签。
                      </audio>
                    </div>

                    <div
                      class="questionsStem"
                      v-html="item.question.stem"
                    ></div>
                  </div>
                  <div class="stem-center"></div>
                  <div class="stem-right">
                    <textarea
                      v-model="item.modelValue"
                      @input="changeText(index)"
                      type="text"
                      v-show="!item.isShowJx"
                      :disabled="item.isShowJx"
                      placeholder="请输入答案"
                    />
                    <div class="btn">
                      <div></div>
                      <el-button
                        :disabled="!item.makeQuestion"
                        type="primary"
                        @click="clickShowJx(index)"
                        :class="item.isShowJx ? 'activeJx' : ''"
                        ><i class="el-icon-s-claim"></i>验证答案</el-button
                      >
                    </div>
                    <div v-if="item.isShowJx">
                      <div class="wenda">
                        <div class="nide">
                          <div>你的答案:</div>

                          <div>
                            {{ item.modelValue }}
                          </div>
                        </div>
                        <div class="jiexi">
                          <div>解析:</div>
                          <div
                            class="praticeAnalysis"
                            v-html="item.question.analysis"
                          ></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- 视频 -->
              <div
                class="questionsVideo"
                v-if="item.question.stem_type == 3 ? true : false"
              >
                <div class="allListStemTitle">
                  <div class="allListTitle">
                    <div class="allListLeftborder"></div>
                    <div>
                      <span>{{ index + 1 }}</span>
                    </div>
                    <div>
                      <span v-if="item.question.defined == '' ? false : true"
                        >({{ item.question.defined }})</span
                      ><span v-else>{{
                        item.question.type == 4 ? "问答题" : ""
                      }}</span>
                    </div>
                    <!-- <div>({{ item.question.grade }}分)</div> -->
                  </div>
                  <div class="common-center">
                    <div></div>
                    <span class="fontweight">答题卡</span>
                  </div>

                  <div class="connction">
                    <div
                      v-if="item.question.collection == 1"
                      @click="clickActive(index)"
                    >
                      <van-icon size="1.5em" color="#ffb41c" name="star" />
                      <span>收藏</span>
                    </div>
                    <div v-else @click="clickActive(index)">
                      <van-icon color="#ccc" name="star" size="1.5em" />
                      <span>收藏</span>
                    </div>

                    <!-- <img
                      @click="sybelBtn(index)"
                      :src="item.biaoji == 0 ? item.sybelImg : item.sybelPath"
                      alt=""
                    />
                    <p>标记</p> -->
                  </div>
                </div>
                <div class="stemBox common-stem2">
                  <div class="stem-left">
                    <video
                      :src="item.question.file.playpath"
                      controls="controls"
                    ></video>
                    <div
                      class="questionsStem"
                      v-html="item.question.stem"
                    ></div>
                  </div>
                  <div class="stem-center"></div>
                  <div class="stem-right">
                    <textarea
                      v-show="!item.isShowJx"
                      v-model="item.modelValue"
                      @change="changeText(index)"
                      type="text"
                      :disabled="item.isShowJx"
                      placeholder="请输入答案"
                    />
                    <div class="btn">
                      <div></div>
                      <el-button
                        :disabled="!item.makeQuestion"
                        type="primary"
                        @click="clickShowJx(index)"
                        :class="item.isShowJx ? 'activeJx' : ''"
                        ><i class="el-icon-s-claim"></i>验证答案</el-button
                      >
                    </div>
                    <div v-if="item.isShowJx">
                      <div class="wenda">
                        <div class="jiexi nide">
                          <div>你的答案:</div>

                          <div>
                            {{ item.modelValue }}
                          </div>
                        </div>
                        <div class="jiexi">
                          <div>解析:</div>
                          <div
                            class="praticeAnalysis"
                            v-html="item.question.analysis"
                          ></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- 图片 -->
              <div
                class="questionsVideo"
                v-if="item.question.stem_type == 4 ? true : false"
              >
                <div class="allListStemTitle">
                  <div class="allListTitle">
                    <div class="allListLeftborder"></div>
                    <div>
                      <span>{{ index + 1 }}</span>
                    </div>
                    <div>
                      <span v-if="item.question.defined == '' ? false : true"
                        >({{ item.question.defined }})</span
                      ><span v-else>{{
                        item.question.type == 4 ? "问答题" : ""
                      }}</span>
                    </div>
                    <!-- <div>({{ item.question.grade }}分)</div> -->
                  </div>
                  <div class="common-center">
                    <div></div>
                    <span class="fontweight">答题卡</span>
                  </div>

                  <div class="connction">
                    <div
                      v-if="item.question.collection == 1"
                      @click="clickActive(index)"
                    >
                      <van-icon size="1.5em" color="#ffb41c" name="star" />
                      <span>收藏</span>
                    </div>
                    <div v-else @click="clickActive(index)">
                      <van-icon color="#ccc" name="star" size="1.5em" />
                      <span>收藏</span>
                    </div>

                    <!-- <img
                      @click="sybelBtn(index)"
                      :src="item.biaoji == 0 ? item.sybelImg : item.sybelPath"
                      alt=""
                    />
                    <p>标记</p> -->
                  </div>
                </div>
                <div class="stemBox common-stem2">
                  <div class="stem-left">
                    <img
                      :src="item.question.file.playpath"
                      alt=""
                      @click="clickShowImagePreview(index)"
                      v-if="item.question.file"
                    />
                    <div
                      class="questionsStem"
                      v-html="item.question.stem"
                    ></div>
                  </div>
                  <div class="stem-center"></div>
                  <div class="stem-right">
                    <textarea
                      v-show="!item.isShowJx"
                      v-model="item.modelValue"
                      @input="changeText(index)"
                      type="text"
                      :disabled="item.isShowJx"
                      placeholder="请输入答案"
                    />
                    <div class="btn">
                      <div></div>
                      <el-button
                        :disabled="!item.makeQuestion"
                        type="primary"
                        @click="clickShowJx(index)"
                        :class="item.isShowJx ? 'activeJx' : ''"
                        ><i class="el-icon-s-claim"></i>验证答案</el-button
                      >
                    </div>
                    <div v-if="item.isShowJx">
                      <div class="wenda">
                        <div class="jiexi nide">
                          <div>你的答案:</div>

                          <div>
                            {{ item.modelValue }}
                          </div>
                        </div>
                        <div class="jiexi">
                          <div>解析:</div>
                          <div
                            class="praticeAnalysis"
                            v-html="item.question.analysis"
                          ></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- 听力题 -->
            <div
              class="listeningChoice"
              v-if="item.question.type == 10 ? true : false"
            >
              <!-- 音频 -->
              <div
                class="listeningAudio"
                v-if="item.question.stem_type == 2 ? true : false"
              >
                <div class="allListStemTitle">
                  <div class="allListTitle">
                    <div class="allListLeftborder"></div>
                    <div>
                      <span>{{ index + 1 }}</span>
                    </div>
                    <div>
                      <span v-if="item.question.defined == '' ? false : true"
                        >({{ item.question.defined }})</span
                      ><span v-else>{{
                        item.question.type == 10 ? "听力题" : ""
                      }}</span>
                    </div>
                    <!-- <div>({{ item.question.grade }}分)</div> -->
                  </div>
                  <div class="common-center">
                    <div></div>
                    <span class="fontweight">答题卡</span>
                  </div>
                  <div class="connction">
                    <div
                      v-if="item.question.collection == 1"
                      @click="clickActive(index)"
                    >
                      <van-icon size="1.5em" color="#ffb41c" name="star" />
                      <span>收藏</span>
                    </div>
                    <div v-else @click="clickActive(index)">
                      <van-icon color="#ccc" name="star" size="1.5em" />
                      <span>收藏</span>
                    </div>

                    <!-- <img
                      @click="sybelBtn(index)"
                      :src="item.biaoji == 0 ? item.sybelImg : item.sybelPath"
                      alt=""
                    />
                    <p>标记</p> -->
                  </div>
                </div>
                <div class="stemBox common-stem2">
                  <div class="stem-left">
                    <div class="audioBox">
                      <div class="btnback" v-if="item.question.file">
                        <div class="back" @click="clickSnd(-15)">
                          <img src="../../assets/image/kuaijin1.png" alt="" />
                        </div>
                        <div>{{ item.question.file.filename }}</div>
                        <div class="forward" @click="clickSnd(15)">
                          <img src="../../assets/image/kuaijin2.png" alt="" />
                        </div>
                      </div>
                      <audio controls controlslist="nodownload">
                        <source
                          :src="item.question.file.playpath"
                          type="audio/mp3"
                        />
                        您的浏览器不支持 HTML5 audio 标签。
                      </audio>
                    </div>

                    <div
                      class="listeningStem"
                      v-html="item.question.stem"
                    ></div>
                  </div>
                  <div class="stem-center"></div>
                  <div class="stem-right">
                    <textarea
                      v-show="!item.isShowJx"
                      v-model="item.modelValue"
                      @input="changeText(index)"
                      type="text"
                      placeholder="请输入答案"
                      :disabled="item.isShowJx"
                    />
                    <div class="btn">
                      <div></div>
                      <el-button
                        :disabled="!item.makeQuestion"
                        type="primary"
                        @click="clickShowJx(index)"
                        :class="item.isShowJx ? 'activeJx' : ''"
                        ><i class="el-icon-s-claim"></i>验证答案</el-button
                      >
                    </div>
                    <div v-if="item.isShowJx">
                      <div class="wenda">
                        <div class="nide">
                          <div>你的答案:</div>
                          <div>
                            {{ item.modelValue }}
                          </div>
                        </div>
                        <div class="jiexi">
                          <div>解析:</div>
                          <div
                            class="praticeAnalysis"
                            v-html="item.question.analysis"
                          ></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-else class="zanwushiti">暂无试题</div>

      <div class="Pratice_left_bottom" v-if="allListData.length > 0">
        <div class="commBtn">
          <el-button
            type="primary"
            @click="commentBool = !commentBool"
            :class="commentBool ? 'boto' : 'top'"
            >评论 {{ discussObj.total }}</el-button
          >
        </div>
        <div>
          <div>
            <el-button
              type="primary"
              :disabled="!upperBool"
              @click="clickShow(-1)"
              >上一题</el-button
            >
          </div>
          <div>
            <el-button>正做题</el-button>
          </div>
          <div>
            <el-button
              type="primary"
              :disabled="!downBool"
              @click="clickShow(1)"
              >下一题</el-button
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import VueDragResize from "vue-drag-resize";
import $ from "jquery";
import { ImagePreview } from "vant";
export default {
  //import引入的组件需要注入到对象中才能使用
  components: {
    VueDragResize,
  },
  head() {
    return {
      title: this.titlename,
    };
  },
  data() {
    //这里存放数据
    return {
      name: "", //试卷名
      gradeall: "", //试卷分数
      classname: "", //试卷分类名
      time: 0, //倒计时
      question: {}, //创建的试卷
      allObj: {}, //所有数据
      allListData: [], //题型列表
      redList: [], //阅读小题
      listSum: [
        "A",
        "B",
        "C",
        "D",
        "E",
        "F",
        "G",
        "H",
        "I",
        "J",
        "K",
        "L",
        "M",
        "N",
        "O",
        "P",
        "Q",
        "R",
        "S",
        "T",
        "U",
        "V",
        "W",
        "X",
        "Y",
        "Z",
      ],
      defaultIndex: 0,
      upperBool: false, //上一题
      downBool: true, //下一题
      number: 0, //已做题目数量
      numbertest: 0, //总数
      icon: 2,
      Imgitems: [],
      biaojiNum: 0,
      difficultys: null,
      colors: ["#F7BA2A", "#F7BA2A", "#FF9900"],
      centerDialogVisible: false,
      DialogVisible: false,
      gridData: "",
      quanpingshow: false,
      width: 0,
      height: 0,
      top: 0,
      left: 0,
      titlename: "", //head
      commentBool: false, //评论
      discussId: 0,
      discussObj: {}, //评论
      commentRedBool: false, //写评论
      discussCreate: "", //评论内容
      pageSize: {
        limit: 6,
        page: 1,
      },
    };
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {
    allListData: {
      handler() {
        let num = 0;
        this.allListData.forEach((v) => {
          if (v.makeQuestion) {
            num += 1;
          }
        });
        this.number = num;
        this.$nextTick(() => {
          $(".blankStem input").each((index, item) => {
            if (this.allListData[this.defaultIndex].question.type == 6) {
              $(item).val(this.allListData[this.defaultIndex].input[index]);
            }
          });
        });
        if (this.defaultIndex == this.allListData.length - 1) {
          this.downBool = false;
        }
      },
      deep: true,
    },
    defaultIndex() {
      let _this = this;
      _this.widthActive = ((_this.defaultIndex + 1) / _this.total) * 100;
      if (_this.defaultIndex == 0) {
        _this.downBool = true; //激活下一题
        _this.upperBool = false; //激活上一题
      } else if (_this.defaultIndex == _this.allListData.length - 1) {
        _this.downBool = false; //激活下一题
        _this.upperBool = true; //激活上一题
      } else {
        _this.downBool = true; //激活下一题
        _this.upperBool = true; //激活上一题
      }
    },
  },

  //方法集合
  methods: {
    clickshowcommenttext(index, index1) {
      let _this = this;
      if (_this.discussObj.data[index].son[index1].commenttextclass) {
        _this.$delete(_this.discussObj.data[index].son[index1], "msg"); //解决视图不跟新
        _this.$set(_this.discussObj.data[index].son[index1], "msg", "收起");
        _this.$delete(
          _this.discussObj.data[index].son[index1],
          "commenttextclass"
        ); //解决视图不跟新
        _this.$set(
          _this.discussObj.data[index].son[index1],
          "commenttextclass",
          false
        );
      } else {
        _this.$delete(_this.discussObj.data[index].son[index1], "msg"); //解决视图不跟新
        _this.$set(_this.discussObj.data[index].son[index1], "msg", "展开");
        _this.$delete(
          _this.discussObj.data[index].son[index1],
          "commenttextclass"
        ); //解决视图不跟新
        _this.$set(
          _this.discussObj.data[index].son[index1],
          "commenttextclass",
          true
        );
      }
    },
    clickshowask(index) {
      let _this = this;
      if (_this.discussObj.data[index].commenttextclass) {
        _this.$delete(_this.discussObj.data[index], "msg"); //解决视图不跟新
        _this.$set(_this.discussObj.data[index], "msg", "收起");
        _this.$delete(_this.discussObj.data[index], "commenttextclass"); //解决视图不跟新
        _this.$set(_this.discussObj.data[index], "commenttextclass", false);
      } else {
        _this.$delete(_this.discussObj.data[index], "msg"); //解决视图不跟新
        _this.$set(_this.discussObj.data[index], "msg", "展开");
        _this.$delete(_this.discussObj.data[index], "commenttextclass"); //解决视图不跟新
        _this.$set(_this.discussObj.data[index], "commenttextclass", true);
      }
    },
    handleCurrentChange(val) {
      let _this = this;
      _this.pageSize.page = val;
      _this.discuss();
    },
    clickDiscussCreate() {
      // 创建评论
      let _this = this;

      if (_this.discussCreate == "") {
        this.$message.error("评论不能为空");
      } else {
        let obj = {
          // question_class_id: _this.question.question_class_id, //试卷id
          question_id: _this.discussId, //试题id
          commenttext: _this.discussCreate, //评论
        };
        if (_this.$route.query.val) {
          obj.question_class_id = _this.allListData[_this.defaultIndex]; //试卷id
          console.log(obj);
        } else {
          obj.question_class_id = _this.question.question_class_id; //试卷id
        }
        _this.$api.discussCreate(obj).then((res) => {
          this.$message({
            message: "发表成功",
            type: "success",
          });

          _this.commentRedBool = false;
          _this.discussCreate = "";
          _this.discuss();
        });
      }
    },
    clickSupport(id, val) {
      // 创建赞/取消赞
      let _this = this;
      let obj = {
        id: id,
      };
      _this.$api.support(obj).then((res) => {
        _this.$toast.success(val);
        _this.discuss();
      });
    },
    discuss() {
      // 获取评论
      let _this = this;
      let obj = {
        question_id: _this.discussId, //试题id
        commentable_type: "Question",
        limit: _this.pageSize.limit,
        page: _this.pageSize.page,
      };
      _this.$api.discuss(obj).then((res) => {
        _this.discussObj = res.data.lists;
        res.data.lists.data.forEach((v) => {
          v.commenttextclass = true; //收起和展开
          v.msg = "展开"; //收起和展开
          v.son.forEach((v1) => {
            v1.commenttextclass = true; //收起和展开
            v1.msg = "展开"; //收起和展开
          });
        });
      });
    },
    clickShowImagePreview(index) {
      // 预览
      let _this = this;
      ImagePreview({
        images: [_this.allListData[index].question.file.playpath],
        closeable: true,
      });
    },
    clickSnd(time) {
      var audio = document.getElementsByTagName("audio");
      audio[0].currentTime += time;
      audio[0].play();
    },
    handleClose() {
      this.top = 0;
      this.left = 0;
      this.quanpingshow = false;
    },
    resize(newRect) {
      this.width = newRect.width;
      this.height = newRect.height;
      this.top = newRect.top;
      this.left = newRect.left;
    },
    clickShowJxRed(i, index) {
      // 阅读解析
      let _this = this;
      _this.$delete(_this.allListData[i].question.reads[index], "isShowJx");
      _this.$set(_this.allListData[i].question.reads[index], "isShowJx", true);
    },
    clickShowJx(i) {
      //解析
      let _this = this;
      _this.$delete(_this.allListData[i], "isShowJx");
      _this.$set(_this.allListData[i], "isShowJx", true);
    },
    clickActive(i) {
      let _this = this;
      if (this.allListData[i].question.collection == 0) {
        //收藏他
        this.allListData[i].question.collection = 1;
        if (_this.$route.query.val == "错题集") {
          _this.allListData.forEach((v) => {
            if (_this.allListData[i].question_id == v.question_id) {
              v.question.collection = 1;
            }
          });
        }
        let obj = {
          collection: 1 /** 收藏动作，1代表收藏，0代表取消收藏 */,
          question_id: this.allListData[i]
            .question_id /** 试题id，     如果不传question_id，代表取消该用户所有的收藏试题 */,
        };

        this.$api.collotion(obj).then((res) => {
          this.$message({
            message: "收藏成功",
            type: "success",
          });
        });
      } else {
        //  取消收藏
        this.allListData[i].question.collection = 0;
        let obj = {
          collection: 0 /** 收藏动作，1代表收藏，0代表取消收藏 */,
          question_id: this.allListData[i]
            .question_id /** 试题id，     如果不传question_id，代表取消该用户所有的收藏试题 */,
        };
        if (_this.$route.query.val == "错题集") {
          _this.allListData.forEach((v) => {
            if (_this.allListData[i].question_id == v.question_id) {
              v.question.collection = 0;
            }
          });
        }
        this.$api.collotion(obj).then((res) => {
          this.$message({
            message: "取消收藏",
            type: "success",
          });
        });
      }
    },
    sybelBtn(i) {
      if (this.allListData[i].biaoji == 0) {
        // this.allListData[i].biaoji = 1;
        this.$delete(this.allListData[i], "biaoji");
        this.$set(this.allListData[i], "biaoji", 1);
        this.biaojiNum += 1;
      } else {
        this.$delete(this.allListData[i], "biaoji");
        this.$set(this.allListData[i], "biaoji", 0);
        this.biaojiNum -= 1;
      }
    },
    changtime(i) {
      this.timeNumber -= 1;
    },
    changeText(i) {
      // 问答题，听力题以做
      let _this = this;
      if (_this.allListData[i].modelValue != "") {
        _this.$delete(_this.allListData[i], "makeQuestion");
        _this.$set(_this.allListData[i], "makeQuestion", true); //是否做题--答题卡
      }
    },
    clickSingle(index, index1) {
      
      // 单选选中
      let _this = this;
      _this.allListData[index].makeQuestion = true; //是否做题--答题卡
      _this.$delete(
        _this.allListData[index].question.single_choices[index1],
        "isSelect"
      ); //解决视图不跟新
      _this.$set(
        _this.allListData[index].question.single_choices[index1],
        "isSelect",
        true
      );
      console.log(_this.allListData[index]);
      _this.allListData[index].question.single_choices.forEach((item, inde) => {
        
        if (inde == index1) {
          item.isSelect = true;
        } else {
          item.isSelect = false;
        }
        
      });
    },
    clickMany(index, index1) {
      // 多选题
      let _this = this;
      _this.allListData[index].makeQuestion = true; //是否做题--答题卡
      if (
        !_this.allListData[index].question.multiple_choices[index1].isSelect
      ) {
        _this.$delete(
          _this.allListData[index].question.multiple_choices[index1],
          "isSelect"
        ); //解决视图不跟新
        _this.$set(
          _this.allListData[index].question.multiple_choices[index1],
          "isSelect",
          true
        );
      } else {
        _this.$delete(
          _this.allListData[index].question.multiple_choices[index1],
          "isSelect"
        ); //解决视图不跟新
        _this.$set(
          _this.allListData[index].question.multiple_choices[index1],
          "isSelect",
          false
        );
      }
    },
    clickJudges(index, index1) {
      // 判断
      let _this = this;
      _this.allListData[index].makeQuestion = true; //是否做题--答题卡
      _this.$delete(
        _this.allListData[index].question.judges[index1],
        "isSelect"
      ); //解决视图不跟新
      _this.$set(
        _this.allListData[index].question.judges[index1],
        "isSelect",
        true
      );

      _this.allListData[index].question.judges.forEach((v, inde) => {
        if (inde == index1) {
          v.isSelect = true;
        } else {
          v.isSelect = false;
        }
      });
    },
    clickReading(index, index1, index2) {
      // 阅读题
      let _this = this;
      _this.allListData[index].makeQuestion = true; //是否做题--答题卡
      let list = _this.allListData[index].question.reads[index1];
      list.makeQuestion = true; //小题是否做
      _this.$delete(
        _this.allListData[index].question.reads[index1].question.single_choices[
          index2
        ],
        "isSelect"
      ); //解决视图不跟新
      _this.$set(
        _this.allListData[index].question.reads[index1].question.single_choices[
          index2
        ],
        "isSelect",
        true
      );
      list.question.single_choices.forEach((v, inde) => {
        if (inde == index2) {
          v.isSelect = true;
        } else {
          v.isSelect = false;
        }
      });
    },
    clickShow(val) {
      //下一题上一题
      let _this = this;
      _this.quanpingshow = false;
      $("#Pratice audio").each((i, v) => {
        v.pause();
      });
      $("#Pratice video").each((i, v) => {
        v.pause();
      });
      _this.defaultIndex += val;
      _this.allListData.forEach((item, index) => {
        if (_this.defaultIndex == index) {
          _this.$delete(_this.allListData[index], "topicBool");
          _this.$set(_this.allListData[index], "topicBool", true);
        } else {
          _this.$delete(_this.allListData[index], "topicBool");
          _this.$set(_this.allListData[index], "topicBool", false);
        }
      });
      if (
        _this.defaultIndex > 0 &&
        _this.defaultIndex < _this.allListData.length - 1
      ) {
        _this.downBool = true; //激活下一题
        _this.upperBool = true; //激活上一题
      } else if (_this.defaultIndex == 0) {
        _this.downBool = true; //激活下一题
        _this.upperBool = false; //去掉上一题
      } else if (_this.defaultIndex == _this.allListData.length - 1) {
        _this.downBool = false; //去掉下一题
        _this.upperBool = true; //激活上一题
      }
      //评论
      _this.discussId = _this.allListData[_this.defaultIndex].question_id;
      _this.discuss();
    },
    clickCard(index) {
      // 点击答题卡
      let _this = this;
      _this.defaultIndex = index;
      _this.allListData.forEach((v) => {
        v.topicBool = false;
      });

      _this.allListData[index].topicBool = true;
      _this.$delete(_this.allListData[index], "topicBool");
      _this.$set(_this.allListData[index], "topicBool", true);
      if (
        _this.defaultIndex > 0 &&
        _this.defaultIndex < _this.allListData.length - 1
      ) {
        _this.downBool = true; //激活下一题
        _this.upperBool = true; //激活上一题
      } else if (_this.defaultIndex == 0) {
        _this.downBool = true; //激活下一题
        _this.upperBool = false; //去掉上一题
      } else if (_this.defaultIndex == _this.allListData.length - 1) {
        _this.downBool = false; //去掉下一题
        _this.upperBool = true; //激活上一题
      }
      //评论
      _this.discussId = _this.allListData[_this.defaultIndex].question_id;
      _this.discuss();
    },
    clickInput(e, i) {
      //多元激活样式
      let _this = this;
      let arr = [];
      $(".blankStem input").each((index, item) => {
        arr.push($(item).val());
      });

      _this.allListData[i].input = arr;
      if (e.target.localName == "input") {
        if (e.target.value != "") {
          _this.$delete(_this.allListData[i], "makeQuestion");
          _this.$set(_this.allListData[i], "makeQuestion", true); //是否做题--答题卡
        }
      }
    },
    records() {
      // 创建试卷
      let _this = this;
      //获取所有数据
      _this.$api.shows(_this.question).then((res) => {
        _this.difficultys =
          res.data.question_class_record.question_class.difficultys;
        // _this.gradeall = res.data.question_class_record.gradeall;
        _this.numbertest = res.data.question_class_record.items.length;
        _this.allObj = res.data.question_class_record; //所有数据
        // _this.time = _this.allObj.question_class.durations * 60 * 1000; //倒计时
        // _this.timeNumber = _this.allObj.question_class.durations * 60;
        _this.allListData = _this.allObj.items.filter(
          (item) => item.question_id_son == null
        ); //题型列表
        _this.redList = _this.allObj.items.filter(
          (item) => item.question_id_son != null
        ); //阅读小题

        // 按照question_id_son分类
        let b = _this.redList.reduce(
          (r, x) => (
            (r[x.question_id_son] || (r[x.question_id_son] = [])).push(x), r
          ),
          {}
        );
        let c = Object.keys(b).map((x) => b[x]);
        //吧单选中的阅读题替换阅读题
        c.forEach((v, i) => {
          v.forEach((item, index) => {
            _this.allListData.forEach((v1, i1) => {
              if (v1.question.type == 9) {
                if (_this.allListData[i1].question_id == item.question_id_son) {
                  v1.question.reads = v;
                }
              }
            });
          });
        });
        _this.allListData.forEach((item, index) => {
          item.topicBool = false; //是否显示题
          item.makeQuestion = false; //是否做题--答题卡
          item.isShowJx = false; //是否显示解析
          item.biaoji = 0;
          item.activeImg = require("../../assets/image/11.png");
          item.pathImg = require("../../assets/image/10.png");
          item.sybelImg = require("../../assets/image/biaoji.png");
          item.sybelPath = require("../../assets/image/activebiaoji.png");
          if (item.question.type == 1) {
            // 单选
            item.question.single_choices.forEach((item1, index1) => {
              item1.isSelect = false;
            });
          } else if (item.question.type == 2) {
            // 多选
            item.question.multiple_choices.forEach((item1, index1) => {
              item1.isSelect = false;
            });
          } else if (item.question.type == 3) {
            //判断题
            item.question.judges.forEach((item1) => {
              item1.isSelect = false;
            });
          } else if (item.question.type == 9) {
            //阅读题
            item.question.reads.forEach((item1) => {
              item1.makeQuestion = false; //是否小做题
              item1.question.single_choices.forEach((item2) => {
                item2.isSelect = false;
                item2.isShowJx = false;
              });
            });
          } else if (item.question.type == 6) {
            // 多元
            item.question.stem = item.question.stem.replace(
              /____/g,
              `<input  placeholder='点击答题'  />`
            );
            var n = item.question.stem.split("input").length - 1;
            item.input = [];
            for (let index = 0; index < n; index++) {
              item.input[index] = "";
            }
            if (item.answers) {
              item.answers = item.answers.substring(0, item.answers.length - 1); //截取最后一个+
              item.answers = item.answers.split("+"); //转成数组

              item.question.multiple_clozes.forEach((item1) => {
                item1.anss = []; //答案
                item1.answers.forEach((item2) => {
                  item1.anss.push(item2.answer);
                });
              });
              // item.anss.includes(v.answers[index])

              item.question.multiple_clozes.forEach((item1, index1) => {
                if (item.answers[index1] != undefined) {
                  if (item.answers[index1].includes(item1.anss)) {
                    item1.succ = true;
                  } else {
                    item1.succ = false;
                  }
                }
              });
            } else {
              item.answers = [];
            }
          } else if (item.question.type == 4) {
            //问答题text框
            item.modelValue = "";
            item.modelScore = "";
          } else if (item.question.type == 10) {
            //听力题text框
            item.modelValue = "";
            item.modelScore = "";
          }
        });
        _this.allListData[_this.defaultIndex].topicBool = true;
        _this.discussId = _this.allListData[_this.defaultIndex].question_id; //默认加载第一题评论
        _this.discuss();
        this.loading.close();
      });
    },
    getCollection() {
      // 创建收藏试卷
      let _this = this;
      //获取所有数据
      this.loadingBool = true;
      let obj = {
        page: 1,
        limit: 99999,
      };
      _this.defaultIndex = Number(_this.$route.query.index);
      _this.$api.collectionlist(obj).then((res) => {
        // _this.difficultys =
        //   res.data.question_class_record.question_class.difficultys;
        // _this.gradeall = res.data.question_class_record.gradeall;
        _this.numbertest = res.data.data.length;
        _this.allObj = res.data.data; //所有数据
        _this.allListData = _this.allObj.filter(
          (item) => item.question_id_son == null
        ); //题型列表
        _this.redList = _this.allObj.filter(
          (item) => item.question_id_son != null
        ); //阅读小题

        // 按照question_id_son分类
        let b = _this.redList.reduce(
          (r, x) => (
            (r[x.question_id_son] || (r[x.question_id_son] = [])).push(x), r
          ),
          {}
        );
        let c = Object.keys(b).map((x) => b[x]);
        //吧单选中的阅读题替换阅读题
        c.forEach((v, i) => {
          v.forEach((item, index) => {
            _this.allListData.forEach((v1, i1) => {
              if (v1.question.type == 9) {
                if (_this.allListData[i1].question_id == item.question_id_son) {
                  v1.question.reads = v;
                }
              }
            });
          });
        });
        _this.allListData.forEach((item, index) => {
          item.topicBool = false; //是否显示题
          item.makeQuestion = false; //是否做题--答题卡
          item.isShowJx = false; //是否显示解析
          item.biaoji = 0;
          item.activeImg = require("../../assets/image/11.png");
          item.pathImg = require("../../assets/image/10.png");
          item.sybelImg = require("../../assets/image/biaoji.png");
          item.sybelPath = require("../../assets/image/activebiaoji.png");
          if (item.question.type == 1) {
            // 单选
            item.question.single_choices.forEach((item1, index1) => {
              item1.isSelect = false;
            });
          } else if (item.question.type == 2) {
            // 多选
            item.question.multiple_choices.forEach((item1, index1) => {
              item1.isSelect = false;
            });
          } else if (item.question.type == 3) {
            //判断题
            item.question.judges.forEach((item1) => {
              item1.isSelect = false;
            });
          } else if (item.question.type == 9) {
            //阅读题
            item.question.reads.forEach((item1) => {
              item1.makeQuestion = false; //是否小做题
              item1.question.single_choices.forEach((item2) => {
                item2.isSelect = false;
                item2.isShowJx = false;
              });
            });
          } else if (item.question.type == 6) {
            // 多元
            item.question.stem = item.question.stem.replace(
              /____/g,
              `<input  placeholder='点击答题'  />`
            );
            var n = item.question.stem.split("input").length - 1;
            item.input = [];
            for (let index = 0; index < n; index++) {
              item.input[index] = "";
            }
            if (item.answers) {
              item.answers = item.answers.substring(0, item.answers.length - 1); //截取最后一个+
              item.answers = item.answers.split("+"); //转成数组

              item.question.multiple_clozes.forEach((item1) => {
                item1.anss = []; //答案
                item1.answers.forEach((item2) => {
                  item1.anss.push(item2.answer);
                });
              });
              // item.anss.includes(v.answers[index])

              item.question.multiple_clozes.forEach((item1, index1) => {
                if (item.answers[index1] != undefined) {
                  if (item.answers[index1].includes(item1.anss)) {
                    item1.succ = true;
                  } else {
                    item1.succ = false;
                  }
                }
              });
            } else {
              item.answers = [];
            }
          } else if (item.question.type == 4) {
            //问答题text框
            item.modelValue = "";
            item.modelScore = "";
          } else if (item.question.type == 10) {
            //听力题text框
            item.modelValue = "";
            item.modelScore = "";
          }
        });
        _this.allListData[_this.defaultIndex].topicBool = true;
        _this.discussId = _this.allListData[_this.defaultIndex].question_id; //默认加载第一题评论
        _this.discuss();
        this.loading.close();
      });
    },
    getShowanalysis() {
      // 创建收藏试卷
      let _this = this;
      //获取所有数据
      this.loadingBool = true;
      let obj = {
        page: 1,
        limit: 99999,
      };
      _this.defaultIndex = Number(_this.$route.query.index);
      _this.$api.errorlist(obj).then((res) => {
        _this.numbertest = res.data.data.length;
        _this.allObj = res.data.data; //所有数据
        let arrListData = [];
        arrListData = res.data.data;
        arrListData.forEach((v) => {
          if (v.read) {
            console.log(v.read.questions[0]);
            let obj = JSON.parse(JSON.stringify(v.question));
            v.question.type = v.read.questions[0].type;
            v.question_id = v.read.questions[0].id;
            v.question.collection = v.read.questions[0].collection;
            v.question.stem = v.read.questions[0].stem;
            v.question.reads = [];
            v.question.reads.push({
              question: JSON.parse(JSON.stringify(obj)),
            });
            v.question.stem_type = v.read.questions[0].stem_type;
            v.question.file = v.read.questions[0].file;
            v.question.analysis = v.read.questions[0].analysis;
            // stem_type
          }
        });
        _this.allListData = arrListData.filter(
          (item) => item.question_id_son == null
        ); //题型列表
        _this.redList = arrListData.filter(
          (item) => item.question_id_son != null
        ); //阅读小题

        // 按照question_id_son分类
        let b = _this.redList.reduce(
          (r, x) => (
            (r[x.question_id_son] || (r[x.question_id_son] = [])).push(x), r
          ),
          {}
        );
        let c = Object.keys(b).map((x) => b[x]);
        //吧单选中的阅读题替换阅读题
        c.forEach((v, i) => {
          v.forEach((item, index) => {
            _this.allListData.forEach((v1, i1) => {
              if (v1.question.type == 9) {
                if (_this.allListData[i1].question_id == item.question_id_son) {
                  v1.question.reads = v;
                }
              }
            });
          });
        });
        _this.allListData.forEach((item, index) => {
          item.topicBool = false; //是否显示题
          item.makeQuestion = false; //是否做题--答题卡
          item.isShowJx = false; //是否显示解析
          item.biaoji = 0;
          item.activeImg = require("../../assets/image/11.png");
          item.pathImg = require("../../assets/image/10.png");
          item.sybelImg = require("../../assets/image/biaoji.png");
          item.sybelPath = require("../../assets/image/activebiaoji.png");
          if (item.question.type == 1) {
            // 单选
            item.question.single_choices.forEach((item1, index1) => {
              item1.isSelect = false;
            });
          } else if (item.question.type == 2) {
            // 多选
            item.question.multiple_choices.forEach((item1, index1) => {
              item1.isSelect = false;
            });
          } else if (item.question.type == 3) {
            //判断题
            item.question.judges.forEach((item1) => {
              item1.isSelect = false;
            });
          } else if (item.question.type == 9) {
            //阅读题
            item.question.reads.forEach((item1) => {
              item1.makeQuestion = false; //是否小做题
              item1.question.single_choices.forEach((item2) => {
                item2.isSelect = false;
                item2.isShowJx = false;
              });
            });
          } else if (item.question.type == 6) {
            // 多元
            item.question.stem = item.question.stem.replace(
              /____/g,
              `<input  placeholder='点击答题'  />`
            );
            var n = item.question.stem.split("input").length - 1;
            item.input = [];
            for (let index = 0; index < n; index++) {
              item.input[index] = "";
            }
            if (item.answers) {
              item.answers = item.answers.substring(0, item.answers.length - 1); //截取最后一个+
              item.answers = item.answers.split("+"); //转成数组

              item.question.multiple_clozes.forEach((item1) => {
                item1.anss = []; //答案
                item1.answers.forEach((item2) => {
                  item1.anss.push(item2.answer);
                });
              });
              // item.anss.includes(v.answers[index])

              item.question.multiple_clozes.forEach((item1, index1) => {
                if (item.answers[index1] != undefined) {
                  if (item.answers[index1].includes(item1.anss)) {
                    item1.succ = true;
                  } else {
                    item1.succ = false;
                  }
                }
              });
            } else {
              item.answers = [];
            }
          } else if (item.question.type == 4) {
            //问答题text框
            item.modelValue = "";
            item.modelScore = "";
          } else if (item.question.type == 10) {
            //听力题text框
            item.modelValue = "";
            item.modelScore = "";
          }
        });
        _this.allListData[_this.defaultIndex].topicBool = true;
        _this.discussId = _this.allListData[_this.defaultIndex].question_id; //默认加载第一题评论
        _this.discuss();
        this.loading.close();
      });
    },
    getQuestionType() {
      // 创建专项练习试卷
      let _this = this;
      //获取所有数据
      this.loadingBool = true;
      let obj = {
        id: _this.$route.query.id,
        msg: {
          type: _this.$route.query.num,
        },
      };
      _this.$api.special(obj).then((res) => {
        console.log(res);
        _this.numbertest = res.data.question_class_record.items.length;
        _this.allObj = res.data.question_class_record.items; //所有数据
        _this.allListData = _this.allObj.filter(
          (item) => item.question_id_son == null
        ); //题型列表
        _this.redList = _this.allObj.filter(
          (item) => item.question_id_son != null
        ); //阅读小题

        // 按照question_id_son分类
        let b = _this.redList.reduce(
          (r, x) => (
            (r[x.question_id_son] || (r[x.question_id_son] = [])).push(x), r
          ),
          {}
        );
        let c = Object.keys(b).map((x) => b[x]);
        //吧单选中的阅读题替换阅读题
        c.forEach((v, i) => {
          v.forEach((item, index) => {
            _this.allListData.forEach((v1, i1) => {
              if (v1.question.type == 9) {
                if (_this.allListData[i1].question_id == item.question_id_son) {
                  v1.question.reads = v;
                }
              }
            });
          });
        });
        _this.allListData.forEach((item, index) => {
          item.question_id = item.id;
          item.topicBool = false; //是否显示题
          item.makeQuestion = false; //是否做题--答题卡
          item.isShowJx = false; //是否显示解析
          item.biaoji = 0;
          item.activeImg = require("../../assets/image/11.png");
          item.pathImg = require("../../assets/image/10.png");
          item.sybelImg = require("../../assets/image/biaoji.png");
          item.sybelPath = require("../../assets/image/activebiaoji.png");
          if (item.question.type == 1) {
            // 单选
            item.question.single_choices.forEach((item1, index1) => {
              item1.isSelect = false;
            });
          } else if (item.question.type == 2) {
            // 多选
            item.question.multiple_choices.forEach((item1, index1) => {
              item1.isSelect = false;
            });
          } else if (item.question.type == 3) {
            //判断题
            item.question.judges.forEach((item1) => {
              item1.isSelect = false;
            });
          } else if (item.question.type == 9) {
            //阅读题
            item.question.reads.forEach((item1) => {
              item1.makeQuestion = false; //是否小做题
              item1.question.single_choices.forEach((item2) => {
                item2.isSelect = false;
                item2.isShowJx = false;
              });
            });
          } else if (item.question.type == 6) {
            // 多元
            item.question.stem = item.question.stem.replace(
              /____/g,
              `<input  placeholder='点击答题'  />`
            );
            var n = item.question.stem.split("input").length - 1;
            item.input = [];
            for (let index = 0; index < n; index++) {
              item.input[index] = "";
            }
            if (item.answers) {
              item.answers = item.answers.substring(0, item.answers.length - 1); //截取最后一个+
              item.answers = item.answers.split("+"); //转成数组

              item.question.multiple_clozes.forEach((item1) => {
                item1.anss = []; //答案
                item1.answers.forEach((item2) => {
                  item1.anss.push(item2.answer);
                });
              });
              // item.anss.includes(v.answers[index])

              item.question.multiple_clozes.forEach((item1, index1) => {
                if (item.answers[index1] != undefined) {
                  if (item.answers[index1] .includes(item1.anss)) {
                    item1.succ = true;
                  } else {
                    item1.succ = false;
                  }
                }
              });
            } else {
              item.answers = [];
            }
          } else if (item.question.type == 4) {
            //问答题text框
            item.modelValue = "";
            item.modelScore = "";
          } else if (item.question.type == 10) {
            //听力题text框
            item.modelValue = "";
            item.modelScore = "";
          }
        });
        console.log(_this.allListData);
        if (_this.allListData.length > 0) {
          _this.allListData[_this.defaultIndex].topicBool = true;
          _this.discussId = _this.allListData[_this.defaultIndex].question_id; //默认加载第一题评论
          _this.discuss();
        }
        this.loading.close();
      });
    },

    clicksubmit() {
      let _this = this;

      if (_this.$route.query.val == "收藏") {
        _this.$router.push("/Index/PersonalCenter/WrongQuestions");
      } else if (_this.$route.query.val == "错题集") {
        _this.$router.push("/Index/PersonalCenter/colltion");
      } else if (_this.$route.query.val == "专项练习") {
        _this.$router.push({
          path: "/Index/PersonalCenter/QuestionType",
          query: {
            id: _this.$route.query.id,
            name: _this.$route.query.name,
          },
        });
      } else {
        _this.$router.push("/Index/Home");
      }
    },
    //材料全屏
    showMax(index) {
      let _this = this;
      _this.quanpingshow = true;
      _this.gridData = _this.allListData[index].question.stem;
    },
    // offset: function (othis) {
    //   var type = othis.data("type"),
    //     text = othis.text();

    //   layer.open({
    //     type: 1,
    //     offset: type, //具体配置参考：http://www.layui.com/doc/modules/layer.html#offset
    //     id: "layerDemo" + type, //防止重复弹出
    //     content: '<div style="padding: 20px 100px;">' + text + "</div>",
    //     btn: "关闭全部",
    //     btnAlign: "c", //按钮居中
    //     shade: 0, //不显示遮罩
    //     yes: function () {
    //       layer.closeAll();
    //     },
    //   });
    // },
    jiaojuan() {
      if (this.number == this.allListData.length) {
        this.DialogVisible = true;
        this.centerDialogVisible = false;
      } else {
        this.DialogVisible = false;
        this.centerDialogVisible = true;
      }
    },
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {
    let _this = this;
    _this.loading = _this.$loading({
      lock: true,
      text: "Loading",
      spinner: "el-icon-loading",
      background: "rgba(0, 0, 0, 0.7)",
    });
    // 收藏
    if (_this.$route.query.val == "收藏") {
      this.titlename = "收藏 - 西知题库";
      _this.getCollection();

      //
    } else if (_this.$route.query.val == "错题集") {
      this.titlename = "错题集 - 西知题库";
      _this.getShowanalysis();
    } else if (_this.$route.query.val == "专项练习") {
      this.titlename = "专项练习 - 西知题库";
      _this.getQuestionType();
    } else {
      _this.name = localStorage.name; //试卷名
      _this.titlename = localStorage.name;
      _this.question = _this.$store.state.question; //创建的试卷
      _this.records();
    }
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},
  beforeCreate() {}, //生命周期 - 创建之前
  beforeMount() {}, //生命周期 - 挂载之前
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {
    this.$nextTick(() => {
      $(".bigjiexi").on("click", ".thumbnail-i", function () {
        ImagePreview({
          images: [$(this).attr("src")],
          closeable: true,
        });
      });
      $(".jiexi").on("click", ".thumbnail-i", function () {
        ImagePreview({
          images: [$(this).attr("src")],
          closeable: true,
        });
      });
      $(".stemBox").on("click", ".thumbnail-i", function () {
        ImagePreview({
          images: [$(this).attr("src")],
          closeable: true,
        });
      });
    });
  },
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
  activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
};
</script>
<style lang='less' scoped>
//@import url(); 引入公共css类
@bgcolor: #ffefe7;
@bgtcolor: #fa8a89;
@redColor: #df3833;
#Pratice {
  height: 868px;
  /deep/font {
    font-family: -apple-system, BlinkMacSystemFont, "Helvetica Neue", Helvetica,
      Segoe UI, Arial, Roboto, "PingFang SC", miui, "Hiragino Sans GB",
      "Microsoft Yahei", sans-serif;
  }
  .activeJx {
    // background: #df3833 !important;
    background: #f38885 !important;
    border-color: #f38885 !important;
  }
  /deep/.tijiaoDialog {
    .el-dialog {
      background: #fff;
      height: 350px;
      padding-top: 10px;

      //  padding-bottom: 110px;
      .el-dialog__header {
        height: 180px;
        background: url("../../assets/image/jiaojuan.png") no-repeat;
        background-size: 100%;
      }
      .el-dialog__body {
        text-align: center;
        padding: 20px 0;
        font-size: 18px;
      }
      .el-dialog__footer {
        .dialog-footer {
          .el-button {
            &:first-child {
              background: @bgtcolor;
              color: #fff;
              border: @bgtcolor;
            }
            &:nth-child(2) {
              background: #f5f5f5;
              color: #333;
              border: #ddd;
            }
          }
        }
      }
    }
  }
  /deep/.vdr.active:before {
    display: none;
  }
  /deep/.vdr-stick {
    display: none;
  }
  /deep/.question {
    width: 800px;
    // z-index: 0;
    z-index: 100 !important;
    margin-left: 285px;
    height: 1000px;
    .el-dialog__title {
      border: 1px solid #ccc;
      position: absolute;
      top: -11px;
      left: 328px;
      border-radius: 0px 0px 20px 20px;
      font-size: 14px;
      padding: 0px 15px;
      background: #eee;
      cursor: move;
    }
    // .el-dialog__title {
    //   background: @redColor;
    //   padding: 5px 10px;
    //   border-radius: 8px;
    //   color: #fff;
    //   font-size: 12px;
    //   cursor: pointer;
    // }
    .el-dialog {
      width: 800px;
      background: #fff;
      box-shadow: 0px 0px 10px #cfcfcf;
      padding: 10px;
      cursor: move;

      .el-dialog__headerbtn {
        font-size: 28px;
      }
      .el-dialog__body {
        // text-align: center;
        color: #000;
        padding: 20px 10px;
        margin-top: 20px;
        font-size: 16px;
        line-height: 30px;
        height: 650px;
        // padding-top: 10px;
        overflow: auto;
        &::-webkit-scrollbar   {
          width: 5px;
          height: 0px;
        }
        &::-webkit-scrollbar-thumb {
          border-radius: 5px;
          // -webkit-box-shadow: inset 0 0 5px rgba(0,  0,  0,  0.2);
          background: #999;
        }
        &::-webkit-scrollbar-track   {
          // -webkit-box-shadow: inset 0 0 5px rgba(0,  0,  0,  0.2);
          border-radius: 5px;
          background: #eee;
        }
      }
      // .el-dialog__footer {
      //   .dialog-footer {
      //     .el-button {
      //       &:first-child {
      //         background: @bgcolor;
      //         color: #fff;
      //         border: @bgcolor;
      //       }
      //       &:nth-child(2) {
      //         background: #f5f5f5;
      //         color: #333;
      //         border: #ddd;
      //       }
      //     }
      //   }
      // }
    }
  }
  width: 1350px;
  margin: 0 auto;
  // height: 87px;
  display: flex;
  // justify-content: space-between;
  padding-top: 20px;
  /deep/.praticeAnalysis {
    min-width: 415px;
    max-width: 898px;
    font-size: 14px !important;
    line-height: 25px;
    font-family: auto !important;
    word-break: break-all;
    /deep/ p {
      min-width: 415px;
      max-width: 898px;
      word-break: break-all;
      /deep/ span {
        min-width: 415px;
        max-width: 898px;
        word-break: break-all;
      }
    }
  }
  .Pratice_left {
    flex: 1;
    box-shadow: 0 0px 10px #cfcfcf;
    height: 100%;
    width: 1026px;
    height: 810px;
    margin-left: 22px;
    background: #fff;
    .zanwushiti {
      padding: 30px;
    }
    /deep/.audioBox {
      // margin: 0 15px;
      border-radius: 8px;
      position: relative;
      padding-top: 50px;
      box-shadow: 0px 0px 10px #cfcfcf;
      margin-bottom: 10px;
      .btnback {
        width: 100%;
        background: #fff;
        position: absolute;
        z-index: 50;
        display: flex;
        align-items: center;
        padding: 5px 25px !important;
        padding-bottom: 0 !important;
        // padding-left: 50px !important;
box-sizing: border-box;
        top: 10px;
        left: 0px;
        img {
          width: 40px !important;
          height: 40px !important;
          cursor: pointer;
          margin: 0;
        }
        div {
          &:nth-child(2) {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            width: 400px;
            margin: 0 10px;
          }
        }
      }
      audio {
        width: 94%;
        height: 55px;
        // outline: none;
        // display: flex;
        // background:#fff !important;
        // filter: invert(180,90,360); //c3 filter(滤镜) 属性
        // color: red;
        margin-left: 10px;
        border-radius: 10px;
      }

      audio::-webkit-media-controls-enclosure {
        background: rgba(255, 255, 255, 1); //白色底
        /* 滤镜反转为 rgba(0, 162, 255, 1);rgba(255, 93, 0, 0.8)  #7eb637   10,10,10  #757575 */
        border-radius: 0px;
      }
      .media-controls-container,
      .media-controls-container {
        background: #7eb637;
        //滤镜反转为 rgba(0, 162, 255, 1);rgba(255, 93, 0, 0.8)  #7eb637
        // border-radius: 10px;
        // color: red;
      }
      audio::-webkit-media-controls-play-button {
        height: 22px;
        width: 22px;
        min-width: 22px;
        // border-radius: 50%;
        flex-basis: 22px;
        // border-color: red;
        //  background: red;
        // background: #000;
        // color: #4280f4;
        // color: #25c17c;
      }
      // source{
      //   background: red;
      // }
      // 音量隐藏
      audio::-webkit-media-controls-volume-control-container {
        display: none !important;
        // color: red;
        // background: red;
      }

      audio::-webkit-media-controls-current-time-display {
        order: 1; //设置弹性盒对象元素的顺序
        color: #000;
        text-shadow: unset;
      }

      audio::-webkit-media-controls-time-remaining-display {
        order: 2;
        color: #000;
        text-shadow: unset;
      }
    }
    .Pratice_left_top {
      width: 100%;
      background: #fff;
      padding: 0 30px;
      height: 70px;
      font-size: 14px;
      color: #666;
      display: flex;
      align-items: center;
      border-radius: 8px;
      > div {
        &:nth-child(1) {
          margin-right: 40px;
          font-size: 16px;
          color: #333;
          cursor: pointer;
        }
        &:nth-child(2) {
          display: flex;
          align-items: center;
          div {
            margin-right: 20px;
            &:last-child {
              margin-left: 20px;
            }
          }
        }
      }
    }
    .Pratice_left_main {
      // margin-top: 10px;
      /deep/.is-disabled {
        background: rgba(224, 53, 48, 0.5) !important;
      }
      margin-bottom: 10px;
      padding: 16px 21px;
      width: 100%;
      background: #fff;
      height: 700px;
      border-radius: 8px;
      video {
        width: 500px;
        // margin: 0 100px;
        margin-bottom: 20px;
      }
      /deep/img {
        width: 200px;
        margin: 0 100px;
        cursor: pointer;
      }
      textarea {
        width: 100%;
        padding: 0 10px;
        line-height: 26px;
        color: #333;
        font-size: 16px;
        box-sizing: border-box;
        border: 1px solid #ddd;
        outline: none;
      }

      .allList {
        position: relative;
        // padding: 0 30px;

        .analysisDa {
          //后期删除
          // height: 200px;
          // overflow: auto;
          .nide {
            .youanswer {
              color: #4280f4;
            }
          }
          .daan {
            > div {
              color: #25c17c;
            }
          }
        }
        .stemBox {
          // height: 660px;
          // padding: 20px 10px;
          box-sizing: border-box;

          &.common-stem {
            height: 642px;
            overflow: auto;
            background: #fff;
            // border: 1px solid #efefef;
            //  text-align: center;
            padding: 10px;
            display: flex;
            flex-direction: column;
            &::-webkit-scrollbar   {
              width: 5px;
              height: 0px;
            }
            &::-webkit-scrollbar-thumb {
              border-radius: 5px;
              // -webkit-box-shadow: inset 0 0 5px rgba(0,  0,  0,  0.2);
              background: #999;
            }
            &::-webkit-scrollbar-track   {
              // -webkit-box-shadow: inset 0 0 5px rgba(0,  0,  0,  0.2);
              border-radius: 5px;
              background: #eee;
            }
          }
          &.common-stem2 {
            width: 100%;
            // height: 660px;
            display: flex;
            justify-content: space-around;
            .stem-left {
              width: 455px;
              height: 660px;
              overflow: auto;
              box-sizing: border-box;
              // border: 1px solid #efefef;
              padding: 10px;
              background: #fff;
              &::-webkit-scrollbar   {
                width: 5px;
                height: 0px;
              }
              &::-webkit-scrollbar-thumb {
                border-radius: 5px;
                // -webkit-box-shadow: inset 0 0 5px rgba(0,  0,  0,  0.2);
                background: #999;
              }
              &::-webkit-scrollbar-track   {
                // -webkit-box-shadow: inset 0 0 5px rgba(0,  0,  0,  0.2);
                border-radius: 5px;
                background: #eee;
              }
            }
            .stem-center {
              width: 1px;
              height: 660px;
              background: #efefef;
              margin: 0 30px;
            }
            .stem-right {
              flex: 1;
              max-height: 660px;
              box-sizing: border-box;
              // border: 1px solid #efefef;
              // padding: 10px;
              background: #fff;
              overflow: auto;
              padding-bottom: 30px;
              padding-right: 10px;
              &::-webkit-scrollbar   {
                width: 5px;
                height: 0px;
              }
              &::-webkit-scrollbar-thumb {
                border-radius: 5px;
                // -webkit-box-shadow: inset 0 0 5px rgba(0,  0,  0,  0.2);
                background: #999;
              }
              &::-webkit-scrollbar-track   {
                // -webkit-box-shadow: inset 0 0 5px rgba(0,  0,  0,  0.2);
                border-radius: 5px;
                background: #eee;
              }
            }
          }
        }
        .allListLeftborder {
          width: 5px;
          height: 16px;
          display: inline-block;
          background: #df3833;
          position: absolute;
          left: -21px;
        }
        .allListStemTitle {
          font-size: 14px;
          height: 30px;
          // padding-bottom: 25px;
          // border-bottom: 1px solid #eee;
          display: flex;
          justify-content: space-between;
          align-items: center;
          position: relative;
          margin-bottom: 17px;
          .common-center {
            position: absolute;
            left: 506px;

            div {
              width: 5px;
              height: 16px;
              display: inline-block;
              background: #df3833;
              position: absolute;
              left: -21px;
            }
          }

          .left {
            width: 20px;
            display: flex;
            align-items: center;
            > span {
              &:nth-child(1) {
                &::before {
                  display: inline-block;
                  content: "";
                  width: 16px;
                  height: 26px;
                  background: url("../../assets/image/edu.svg") no-repeat 50%;
                  background-size: 100%;
                }
              }
              &:nth-child(2) {
                height: 16px;
                vertical-align: 6px;
                width: 1px;
              }
            }
            .right-line {
              font-size: 14px;
              height: 18px;
              line-height: 18px;

              padding-left: 1px;
              border-radius: 2px;
              text-align: center;
              background-color: #ccc;
              color: #fff;
              margin-left: 4px;
            }
            .index {
              font-size: 14px;
              height: 20px;
              line-height: 18px;
              padding: 0 5px;
              border-radius: 2px;
              text-align: center;
              background-color: #fa8a89;
              color: #fff;
              margin: 0 15px;
            }
          }
        }
        /deep/ .connction {
          cursor: pointer;
          > div {
            display: flex;
            align-items: center;
          }
          span {
            font-size: 14px;
          }
          img {
            width: 14px;
            margin: 0 6px 0 20px !important;
            &:nth-child(3) {
              display: none;
            }
          }
          p {
            &:nth-child(4) {
              display: none;
            }
          }

          // background: url("../../assets/image/10.png") no-repeat -5px 10px;
        }
        // .allListStemTitle {
        //   line-height: 30px;
        //   font-size: 14px;
        //   height: 30px;
        //   padding-bottom: 25px;
        //   border-bottom: 1px solid #eee;
        //   display: flex;
        //   align-items: center;
        //   margin-bottom: 20px;
        //   &::before {
        //     display: inline-block;
        //     content: "";
        //     width: 16px;
        //     height: 29px;
        //     line-height: 30px;
        //     background: url("../../assets/image/edu.svg") no-repeat 50%;
        //     background-size: 100%;
        //   }
        //   .index {
        //     font-size: 14px;
        //     height: 18px;
        //     line-height: 18px;
        //     padding: 0 5px;
        //     border-radius: 2px;
        //     text-align: center;
        //     background-color: #fa8a89;
        //     color: #fff;
        //     margin: 0 20px;
        //   }
        //   span {
        //     height: 16px;
        //     vertical-align: 6px;
        //     width: 1px;
        //     background: #dfe1e6;
        //   }
        // }
        .allListTitle {
          display: flex;
          align-items: center;
          font-size: 16px;
          color: #000;
          font-weight: bold;
          padding: 30px 0;

          > div {
            &:nth-child(2) {
              padding-top: 6px;
            }
            &:nth-child(3) {
              margin: 0 10px;
            }
          }
        }
        .singleChoice,
        .multipleChoice,
        .judgesChoice {
          .singleStem {
            margin-bottom: 10px;
          }
          .analysisDa {
            width: 100%;
            min-height: 160px;
            border: 1px solid #ccc;
            margin-top: 18px;

            border-radius: 10px;

            > div {
              display: flex;
              &:first-child {
                padding: 15px;
                .youanswer {
                  display: flex;
                  align-items: center;
                  margin-top: 4px;
                }
                .daan {
                  margin-left: 104px;
                  > div {
                    &:first-child {
                      color: #000;
                      margin-bottom: 8px;
                      height: 20px;
                    }
                  }
                }
              }
            }
            .jiexi {
              padding: 24px 15px;
              > div {
                &:first-child {
                }
              }
            }
          }
        }
        .singleChoice {
          position: relative;
          //单选题
          // .btn {
          //   position: absolute;
          //   bottom: 170px;
          //   left: 10px;
          // }
          // .analysis {
          //   position: absolute;
          //   bottom: -40px;
          //   left: 10px;
          // }
          // .singleStem {
          //   // padding: 15px 0 11px;
          // }
          .singleSelectList {
            padding: 5px 0 5px 10px;
            line-height: 24px;
            cursor: pointer;
            display: flex;
            align-items: center;
            // text-align: center;
            // position: relative;
            // height: 35px;
            div {
              display: inline-block;
              width: 24px;
              height: 24px;
              line-height: 24px;
              font-size: 12px;
              background: #eee;
              text-align: center;
              margin-right: 10px;
              border-radius: 50%;
              // position: absolute;
              // left: 43%;
              // transform: translateX(50%);
              &.isSelect {
                // background: @bgcolor;
                background: rgba(255, 56, 51, 0.3);
                color: #fff;
              }
            }
            // p{
            //   display:inline-block;
            // position: absolute;
            // left: 48%;
            // }
          }
        }
        .multipleChoice {
          //多选题
          // .btn {
          //   position: absolute;
          //   bottom: 170px;
          //   left: 10px;
          // }
          .analysis {
            .daan {
              span {
                color: #25c17c;
              }
            }
          }
          .multipleStem {
            padding: 15px 0 11px;
          }
          .multipleSelectList {
            padding: 5px 0 5px 10px;
            line-height: 24px;
            cursor: pointer;
            display: flex;
            align-items: center;
            div {
              width: 24px;
              height: 24px;
              line-height: 24px;
              font-size: 12px;
              background: #eee;
              text-align: center;
              margin-right: 10px;
              border-radius: 50%;
              &.isSelect {
                background: rgba(255, 56, 51, 0.3);
                color: #fff;
              }
            }
          }
        }
        .judgesChoice {
          //判断题
          position: relative;
          // .btn {
          //   position: absolute;
          //   bottom:240px;
          //   left: 10px;
          // }
          .analysis {
            .nide {
              span {
                color: #4280f4;
              }
            }
            .daan {
              span {
                color: #25c17c;
              }
            }
          }
          .judgesStem {
            padding: 15px 0 11px;
          }
          .judgesSelectList {
            padding: 5px 0 5px 10px;
            line-height: 24px;
            cursor: pointer;
            display: flex;
            align-items: center;
            div {
              width: 24px;
              height: 24px;
              line-height: 24px;
              font-size: 12px;
              background: #eee;
              text-align: center;
              margin-right: 10px;
              border-radius: 50%;
              &.isSelect {
                background: rgba(255, 56, 51, 0.3);
                color: #fff;
              }
            }
          }
        }
        .blankChoice {
          //多元填空
          //   position: relative;
          .blankStem {
            padding: 15px 0 11px;
            background: #fff;
            // /deep/span {
            //   background: #f9f9f9 !important;
            // }
          }
          .analysis {
            .daan {
              > div {
                width: 100%;
                min-height: 160px;
                border: 1px solid #ccc;

                border-radius: 10px;
                margin-bottom: 20px;
                padding: 15px 34px;
                &:first-child {
                  margin-top: 14px;
                }
                > div {
                  &:first-child {
                    // display: flex;
                    font-size: 16px;
                    .blankChoice-answer {
                      // margin-left: 36px;
                    }
                  }
                  &:nth-child(2) {
                    margin-top: 6px;
                  }
                }
              }
              .nide {
                margin-top: 6px;
                margin-bottom: 6px;
                span {
                  color: #4280f4;
                }
              }
              .reference {
                display: flex;
                div {
                  color: #25c17c;
                  margin-left: 5px;
                }
              }
            }
          }
          /deep/input {
            color: #2d56b1;
            border: none;
            border-bottom: 1px solid #333;
            width: 180px;
            padding: 0 5px;
            text-align: center;
            font-size: 14px;
            // background: #f8f8f8;
          }
          /deep/.stemBox {
            .blankStem {
              line-height: 30px;
            }
          }
        }
        .readingChoice,
        .questionsChoice,
        .listeningChoice {
          /deep/.fontweight {
            font-size: 16px;
            color: #000;
            font-weight: bold;
          }
          .wenda {
            margin-top: 12px;

            .nide {
              width: 100%;
              min-height: 92px;
              margin-bottom: 20px;
              border: 1px solid #ccc;

              border-radius: 10px;

              padding: 15px;

              > div {
                font-size: 16px;
                &:nth-child(2) {
                  color: #4280f4;

                  margin-top: 4px;
                }
              }
            }
            .jiexi {
              width: 100%;
              min-height: 160px;
              border: 1px solid #ccc;
              padding: 15px;
              //  font-family: -apple-system,BlinkMacSystemFont,'Helvetica Neue',Helvetica,Segoe UI,Arial,Roboto,'PingFang SC',miui,'Hiragino Sans GB','Microsoft Yahei',sans-serif;
              border-radius: 10px;
              margin-bottom: 20px;
              &::-webkit-scrollbar   {
                width: 5px;
                height: 0px;
              }
              &::-webkit-scrollbar-thumb {
                border-radius: 5px;
                // -webkit-box-shadow: inset 0 0 5px rgba(0,  0,  0,  0.2);
                background: #999;
              }
              &::-webkit-scrollbar-track   {
                // -webkit-box-shadow: inset 0 0 5px rgba(0,  0,  0,  0.2);
                border-radius: 5px;
                background: #eee;
              }
            }
          }
        }
        .readingChoice {
          //阅读题
          position: relative;

          //   .btn{
          //   position: absolute;
          //   bottom: 170px;
          //   right:20px;

          // }
          .showMax {
            width: 90px;
            height: 35px;
            padding-left: 20px;
            font-size: 14px;
            background: url(../../assets/image/fangda.png) 5px 8px no-repeat
              #df3833;
            background-size: 18px;
            color: #fff;
            line-height: 35px;
            border-radius: 5px;
            text-align: center;
            position: absolute;
            top: 5px;
            left: 370px;
            cursor: pointer;
          }
          .analysisDa {
            margin-top: 18px;
            border: 1px solid #ccc;
            width: 100%;
            min-height: 160px;
            overflow: auto;

            border-radius: 10px;
            > div {
              &:first-child {
                padding: 20px 0 0 15px;
                .youanswer {
                  display: flex;
                  align-items: center;
                }
                .daan {
                  margin-left: 104px;
                  div {
                    color: #000;
                  }
                  span {
                    color: #25c17c;
                  }
                }
              }
            }
            .jiexi {
              padding: 24px 15px;
              div {
                &:first-child {
                  width: 60px;
                }
              }
            }
          }
          .readingStem {
            padding: 0px 0 11px;
            line-height: 30px;
          }
          .readingSelectList {
            padding: 5px 0 5px 10px;
            line-height: 24px;
            cursor: pointer;
            display: flex;
            // align-items: center;
            p {
              width: 380px;
            }
            div {
              width: 24px;
              height: 24px;
              line-height: 24px;
              font-size: 12px;
              background: #eee;
              text-align: center;
              margin-right: 10px;
              border-radius: 50%;
              &.isSelect {
                background: rgba(255, 56, 51, 0.3);
                color: #fff;
              }
              p {
                width: 300px;
              }
            }
          }
          /deep/ .readTopic {
            // margin-top: 10px;
            margin-right: 10px;
            margin-bottom: 10px;
            padding-bottom: 20px;
            border-bottom: 1px solid #ccc;
            &:last-child {
              border: none;
            }
          }
          .bigjiexi {
            width: 100%;
            max-height: 290px;
            border: 1px solid #ccc;
            margin-bottom: 10px;
            overflow: auto;

            border-radius: 10px;
            padding: 20px 15px;
            margin-top: 18px;
             &::-webkit-scrollbar {
              width: 5px;
              height: 0px;
            }
            &::-webkit-scrollbar-thumb  {
              border-radius: 5px;
              background: #999;
            }
            &::-webkit-scrollbar-track {
              border-radius: 5px;
              background: #eee;
            }
          }
        }
        .questionsChoice {
          //问答题
          position: relative;
          /deep/table {
            width: 420px !important;
            overflow-x: auto;
            td {
              border-bottom: 1px solid #000 !important;

              border-right: 1px solid #000 !important;
              border-top: none !important;
              border-left: none !important;
            }

            border-top: 1px solid #000 !important;

            border-left: 1px solid #000 !important;

            border-spacing: 0 !important; /*去掉单元格间隙*/
          }
          textarea {
            width: 100%;
            height: 200px;
            resize: none;
            border-radius: 8px;
            padding: 20px;
          }

          /deep/ .questionsStem {
            padding: 15px 0 11px;
            line-height: 30px;
          }
        }
        .listeningChoice {
          //听力题
          /deep/table {
            width: 420px !important;
            overflow-x: auto;
            td {
              border-bottom: 1px solid #000 !important;

              border-right: 1px solid #000 !important;
              border-top: none !important;
              border-left: none !important;
            }

            border-top: 1px solid #000 !important;

            border-left: 1px solid #000 !important;

            border-spacing: 0 !important; /*去掉单元格间隙*/
          }
          position: relative;
          //   .btn{
          //   position: absolute;
          //    bottom: 190px;
          //   right: 350px;

          // }
          textarea {
            width: 100%;
            height: 400px;
            resize: none;
            border-radius: 8px;
            padding: 20px;
          }
          // .wenda {
          //   position: absolute;
          //   right:20px;
          //   width: 450px;
          //   bottom:35px;
          //  height: 150px;
          //   overflow: auto;
          //   padding:10px 30px;
          //   box-sizing: border-box;
          //   &::-webkit-scrollbar   {
          //     width: 5px;
          //     height: 1px;
          //   }
          //   &::-webkit-scrollbar-thumb {
          //     border-radius: 5px;
          //     // -webkit-box-shadow: inset 0 0 5px rgba(0,  0,  0,  0.2);
          //     background: #999;
          //   }
          //   &::-webkit-scrollbar-track   {
          //     // -webkit-box-shadow: inset 0 0 5px rgba(0,  0,  0,  0.2);
          //     border-radius: 5px;
          //     background: #eee;
          //   }
          //   // padding: 30px;
          // }
          .listeningStem {
            padding: 15px 0 11px;
          }
        }
      }
      .el-button {
        width: 100px;
        height: 30px;
        background-color: #df3833;
        border: none;
        margin-top: 10px;
        padding: 0;
        line-height: 30px;
        background-image: url("../../assets/image/yanzheng.png") no-repeat;
      }
    }

    .Pratice_left_bottom {
      height: 80px;
      border-radius: 8px;
      background: #fff;
      display: flex;
      // justify-content: space-between;
      // align-items: center;
      justify-content: center;
      padding: 0 30px;
      position: relative;
      .commBtn {
        position: absolute;
        left: 50px;
        top: 20px;

        button {
          background: @redColor;
          border: @redColor;
          padding-left: 35px;
          &.top {
            background: url(../../assets/image/top.png) no-repeat 10px 8px
              @redColor;
            background-size: 18px 18px;
          }
          &.boto {
            background: url(../../assets/image/boto.png) no-repeat 10px 10px
              @redColor;
            background-size: 18px 18px;
          }
        }
      }
      div {
        display: flex;
        align-items: center;
        justify-content: center;
        > div {
          &:nth-child(2) {
            button {
              background: #e03530;
              color: #fff;
              border-radius: 0px;
              border-color: #e03530;
            }
          }
          &:nth-child(1) {
            button {
              border-radius: 4px 0 0 4px;
              border-right: none;
              span {
                margin-right: 10px;
              }
            }
          }
          &:nth-child(3) {
            button {
              border-radius: 0px 4px 4px 0px;
              border-left: none;
              span {
                margin-left: 10px;
              }
            }
          }
          .el-button {
            color: #717786;
            border: solid 1px #ccc;
            background: none;
          }
        }
      }
    }
  }
  .Pratice_right {
    height: 100%;
    position: relative;
    /deep/ spanyes {
      font-family: none;
    }
    /deep/ font {
      font-family: none;
    }
    // 评论
    /deep/.comment {
      width: 300px;
      position: absolute;
      top: -65px;
      font-size: 15px;
      /deep/.el-dialog__header {
        display: none;
      }
      .commentTop {
        display: flex;
        background: #fff;
        padding: 20px;
        font-size: 15px;
        justify-content: space-between;
        margin-bottom: 10px;
        border-bottom: 1px solid #dfe1e6;
        div {
          &:nth-child(1) {
            cursor: pointer;
          }
          &:nth-child(3) {
            cursor: pointer;
            background: url(../../assets/image/xie.png) no-repeat;
            background-size: 18px;
            padding-left: 20px;
          }
        }
      }
      .discuss {
        padding: 0 20px;
        background: #fff;
        font-size: 15px;
        width: 100%;
        height: 570px;
        overflow-y: auto;
        overflow: auto;
        &::-webkit-scrollbar   {
          width: 5px;
          height: 0px;
        }
        &::-webkit-scrollbar-thumb {
          border-radius: 5px;
          // -webkit-box-shadow: inset 0 0 5px rgba(0,  0,  0,  0.2);
          background: #999;
        }
        &::-webkit-scrollbar-track   {
          // -webkit-box-shadow: inset 0 0 5px rgba(0,  0,  0,  0.2);
          border-radius: 5px;
          background: #eee;
        }
        .discuss_list {
          // padding-bottom: 40px;

          li {
            display: flex;
            padding: 10px 0px;
            border-bottom: 1px solid #dfe1e6;
            &:last-child {
              border: none;
            }
            img {
              width: 30px;
              height: 30px;
              margin-right: 15px;
              border-radius: 50%;
              padding: 0;
            }
            .discuss_list_right {
              width: 210px;
              font-size: 13px;
              .discuss_list_top {
                display: flex;
                justify-content: space-between;
                color: #666;
                font-size: 15px;
                vertical-align: middle;
                margin-right: 5px;
                padding: 2px 0;
                img {
                  width: 20px;
                  height: 20px;
                  padding: 0;
                  cursor: pointer;
                }
                .discuss_list_thumbs {
                  display: flex;
                  align-items: center;
                  color: #bfbfbf;
                }
              }
              .discuss_list_time {
                color: #919191;
                padding: 2px 0;
              }
              .discuss_list_ask {
                // line-height: 60px;
                padding: 2px 0;
                // height: 70px;
                // display: -webkit-box;
                // -webkit-box-orient: vertical;
                // -webkit-line-clamp: 4;
                // overflow: hidden;
                // width: 200px;
                position: relative;
                .commenttext {
                  height: 70px;
                  display: -webkit-box;
                  -webkit-box-orient: vertical;
                  -webkit-line-clamp: 4;
                  overflow: hidden;
                  width: 200px;
                }
                button {
                  // width: 40px;
                  height: 18px;
                  border: none;
                  color: #df3833;
                  background: #fff !important;
                  &.active {
                    position: absolute;
                    bottom: 5px;
                    right: -8px;
                  }
                }
              }
              .discuss_list_answer {
                background: #f2f2f2;
                word-break: break-all;
                padding: 2px;

                .discuss_list_answer_name {
                  color: #4280f4;
                  white-space: nowrap;
                }
                .commenttext {
                  display: -webkit-box;
                  -webkit-box-orient: vertical;
                  -webkit-line-clamp: 1;
                  overflow: hidden;
                  width: 120px;
                  height: 20px;
                }
                p {
                  // display: flex;
                  align-items: center;
                }

                // .discuss_list_answer_stem {
                //    height: 35px;
                //   display: -webkit-box;
                //   -webkit-box-orient: vertical;
                //   -webkit-line-clamp: 2;
                //   overflow: hidden;
                //   width: 280px;
                // }
                button {
                  // width: 40px;
                  height: 20px;
                  border: none;
                  color: #df3833;
                }
              }
            }
          }
        }
        > div {
          // padding: 40px 0px;
          margin: 0 auto;
          text-align: center;
          margin-left: -13px;
          padding-top: 40px;
        }
      }
      .el-pagination {
        text-align: center;
        background: #fff;
        padding: 10px 0;
      }
      .el-dialog__body {
        background: #f2f2f2;
        padding: 0px;
        width: 100%;
      }
    }
    // 写品论
    .commentRedBool {
      textarea {
        width: 100%;
        border: none;
        resize: none;
        font-size: 14px;
        line-height: 1.5;
        height: 200px;
        padding: 20px;
        border: 1px solid #cfcfcf;
      }
      .el-button--primary {
        background: @redColor;
        border-color: @redColor;
      }
    }
    width: 300px;
    // background: #fff;
    .Pratice_text-name {
      width: 100%;
      background: #fff;
      text-align: center;
      // height: 50px;
      padding-top: 10px;
      border-radius: 8px;
      margin-bottom: 10px;
      box-shadow: 0 0px 10px #cfcfcf;
      > div {
        padding: 10px 20px;

        p {
          font-size: 14px;
          color: #999;
        }
        > div {
          display: flex;
          justify-content: center;
          /deep/.el-rate {
            .el-rate__icon {
              margin-right: 0px;
            }
          }
        }
      }
      > p {
        font-size: 16px;
        font-weight: bold;
        color: #000;
      }
    }
    .Pratice_right_top {
      height: 174px;
      box-sizing: border-box;
      padding: 17px 20px;
      background: #fff;
      border-radius: 8px;
      margin-bottom: 10px;
      box-shadow: 0 0px 10px #cfcfcf;
      > div {
        &:nth-child(1) {
          font-size: 16px;
          color: #8d929d;
          img {
            display: inline-block;
            width: 21px;
            height: 18px;
            vertical-align: middle;
            margin-right: 13px;
          }
        }
        &:nth-child(2) {
          display: flex;
          align-items: center;
          margin-bottom: 20px;
        }
        .num {
          line-height: 12px;
          font-size: 12px;
          color: #999;
        }
      }
      button {
        // margin: 0 20px 15px;
        background: #df3833;
        color: #fff;
        font-size: 16px;
        border-radius: 6px;
        text-align: center;
        width: 260px;
        cursor: pointer;
      }
      > div {
        &:nth-child(1) {
          text-align: center;
          line-height: 30px;
        }
      }
      .speed {
        width: 100%;
        height: 10px;
        border-radius: 5px;
        overflow: hidden;
        background-color: #f5f5f5;
        margin-right: 10px;
        //
        div {
          height: 100%;
          width: 0;
          background: #df3833;
        }
      }
      .time {
        padding: 15px 10px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .countDown {
          /deep/ .van-count-down {
            font-size: 24px;
            color: #666;
            line-height: 40px;
            padding: 0 15px 0 12px;
            border-right: 1px solid #eee;
            margin-right: 8px;
          }
        }
        .timeDown {
          display: flex;
          > div {
            width: 30px;
            height: 30px;
            &:nth-child(1) {
              margin: 5px 12px;
              background: url(../../assets/image/013.png) no-repeat;
              background-size: 100% 100%;
              cursor: pointer;
            }
            &:nth-child(2) {
              margin: 5px 12px;
              background: url(../../assets/image/014.png) no-repeat;
              background-size: 100% 100%;
              cursor: pointer;
            }
          }
        }
      }
    }
    .Pratice_right_main {
      box-shadow: 0 0px 10px #cfcfcf;
      margin-top: 10px;
      background: #fff;
      padding: 20px 0;
      border-radius: 8px;
      height: 545px;
      // overflow: auto;
      .Pratice_right_main_title {
        font-size: 16px;
        color: #000;
        font-weight: bold;
        line-height: 16px;
        // border-bottom: 3px solid #eee;
        // margin-boompx;
        padding-bottom: 16px;
        position: relative;
        padding-left: 20px;
        &:before {
          content: " ";
          display: block;
          position: absolute;
          width: 5px;
          height: 14px;
          background: #df3833;
          // border: 1px solid #fff;
          box-sizing: border-box;
          left: 0;
        }
      }
      > ul {
        padding: 20px;
        display: flex;
        // justify-content: space-between;
        li {
          margin: 0 10px 0 0;
          position: relative;
          i {
            width: 16px;
            height: 16px;
            margin-right: 6px;
            border-radius: 4px;
            display: inline-block;
            border: 1px solid #ccc;
            vertical-align: sub;
            box-sizing: border-box;
          }
          .index__yizuo {
            background: rgba(255, 56, 51, 0.3);
            border: none;
          }
          .index__weizuo {
            background: #fff;
          }
          .index__biaoji {
            border: 1px solid #ccc;
            background: #fff;
          }
          .index__biaojixi {
            background: url("../../assets/image/16.png") no-repeat 0 0;
            position: absolute;
            left: 0;
            top: 5px;
            border: none;
          }
        }
        // li {
        //   display: block;
        //   margin-right: 10px;
        //   font-size: 12px;
        //   // line-height: 16px;
        //   color: #666;
        //   width: 82px;
        //   height: 45px;
        //   box-sizing: border-box;
        //   border: 1px solid;
        //   padding: 5px;
        //   // padding-right: 0;
        //   border-radius: 2px;
        //   justify-content: space-around;
        //   justify-items: center;
        //   align-items: center;

        //   .text_done_right {
        //     flex: 1;
        //     p {
        //       font-size: 12px;
        //       color: #000;
        //       &:nth-child(2) {
        //         font-size: 12px;
        //         color: #a5a1a5;
        //       }
        //     }
        //   }
        //   // text-align: center;
        //   // line-height: 43px;
        //   display: flex;
        //   justify-content: space-around;
        //   i {
        //     border-radius: 4px;
        //     display: inline-block;
        //     // border: 1px solid #ccc;
        //     vertical-align: sub;
        //     box-sizing: border-box;
        //     img {
        //       width: 19px;
        //       height: 18px;
        //       object-fit: cover;
        //     }
        //   }
        //   &:nth-child(1) {
        //     border-color: #f19d81;
        //     background: #ffefe7;
        //   }
        //   &:nth-child(2) {
        //     border-color: #c8e9ff;
        //     background: #f0faff;
        //   }
        //   &:nth-child(3) {
        //     border-color: #dedede;
        //     background: #fff;
        //   }
        // }
      }

      .index__BigtiNumberBox {
        margin: 0 10px 0 20px;
        .stem {
          margin: 12px 0;
        }
        ul {
          max-height: 430px;
          overflow: auto;
          display: flex;
          flex-wrap: wrap;
           &::-webkit-scrollbar {
            width: 5px;
            height: 0px;
          }
          &::-webkit-scrollbar-thumb  {
            border-radius: 5px;
            background: #999;
          }
          &::-webkit-scrollbar-track {
            border-radius: 5px;
            background: #eee;
          }
        }
        li {
          display: inline-block;
          padding: 0 !important;
          font-size: 12px;
          position: relative;
          width: 26px;
          height: 26px;
          line-height: 26px !important;
          color: #333;
          text-align: center;
          border: 1px solid #ccc;
          background: #fff;
          margin: 0 15px 15px 0;
          border-radius: 4px;
          overflow: hidden;
          cursor: pointer;
          &.active {
            background-color: rgba(255, 56, 51, 0.3);
            color: #fff !important;
            border: none;
          }
          &:nth-child(6n) {
            margin-right: 0;
          }
          &.cur {
            background-image: url(../../assets/image/16.png);
            background-position: 0 0;
            background-repeat: no-repeat;
          }
          &.act {
            border-color: #df3833;
            color: #df3833;
          }
        }
      }
    }
  }
}
</style>